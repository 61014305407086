import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";
// import useScrollRedirect from "../hooks/useScrollRedirect";

export default function PortfolioFreshLeaf() {

    // useScrollRedirect('/portfolio/radheysweet')

    return (
        <>
            <Helmet>
                <title>FreshLeaf Teas | Natural & Health-Friendly Tea Selection | Box Web Agency Portfolio</title>
                <meta name="title" content="FreshLeaf Teas | Natural & Health-Friendly Tea Selection | Box Web Agency Portfolio" />
                <meta name="description" content="Explore our portfolio showcasing FreshLeaf, the purveyor of natural and health-friendly teas. Witness how Box Web Agency contributed to their success story by enhancing their online presence and bringing their premium teas to tea enthusiasts worldwide." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, UIUX, Fresh Leaf Teas, Natural Teas, Health-Friendly Teas, Box Web Agency, Web Design, Web Development, E-commerce, Shopify, Tea Selection, Online Portfolio, Client Success Stories, Web Development Services, Premium Tea Brands, Tea Enthusiasts, Tea E-commerce, Digital Marketing, Responsive Design, SEO Optimization, Tea Retailer, Tea Industry, E-commerce Showcase, E-commerce Success, Website Design, Website Development" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/freshleaf" />
            </Helmet>

            <Header />

            <div className="page_bg bg_freshleaf">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">freshleaf</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1697525379.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2022</p>
                                    <h1 className="services_title freshleaf_text mb-0">
                                        Serving You a Fresh <br /> Experience in Tea Bliss
                                        {/* <span className="freshleaf_text">
                                            Adventure Haven: <br />
                                            Gear Up for Fun!
                                        </span> */}
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light mb-0">Freshleaf, an Indian tea brand, is dedicated to offering ethical, sustainable, and diverse tea blends for everyone. Despite having an excellent product, they faced challenges in presentation and online presence. Recognizing their potential, we at aBox provided comprehensive branding services. This included designing a premium website, offering creative ideas to showcase their products to the right audience, and developing overall visuals for the Shopify store for the international market. Now, their tea brand is not only exceptional in quality but also shines brightly in the digital space.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://freshleafteas.in/" target="_blank" className="d-flex align-items-center text_shiska">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>3D Render</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                                <li>For Startup</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Specialty Tea and Beverages</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center freshleaf_banner">
                                <img src="/assets/image/portfolios/freshleaf/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/freshleaf/freshleaf2.webp" className="w-100" alt="FreshLeaf" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Building a Strong Foundation for a Delightful Tea Haven</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        Our tech-savvy experts have poured their expertise into ensuring FreshLeafs' vision materializes with utmost precision. Every feature has been custom-tailored to cater to their unique needs, resulting in a seamless online shopping experience. Our user-friendly interface showcases an extensive range of tea offerings that promise a delightful experience with every sip.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/freshleaf/freshleaf3.webp"
                                            alt="FreshLeaf" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/freshleaf/freshleaf4.webp"
                                            alt="FreshLeaf" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/freshleaf/freshleaf5.webp"
                                            alt="FreshLeaf" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/freshleaf/freshleaf6.webp"
                                            alt="FreshLeaf" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/freshleaf/freshleaf7.webp"
                                    alt="FreshLeaf" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect freshleaf_banner">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/freshleaf/freshleaf8.webp"
                                        alt="FreshLeaf" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/freshleaf/freshleaf9.webp"
                                        alt="FreshLeaf" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/freshleaf/freshleaf10.webp"
                                        alt="FreshLeaf" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Elevating the Essence of FreshLeafs with Finesse</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">
                                        FreshLeafs opens doors to a world of tea, where tradition meets modern convenience, and every visit is an opportunity to savor the harmony of taste and sophistication. Come and explore this enchanting journey at <a href="https://freshleafteas.in/" target="_blank" className="services_link text_light">www.freshleafteas.in</a>, where every tea blend is a fresh leaf in your story of exquisite taste.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/freshleaf/freshleaf11.webp"
                                        alt="FreshLeaf" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/freshleaf/freshleaf12.webp"
                                        alt="FreshLeaf" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/freshleaf/freshleaf13.webp"
                                        alt="FreshLeaf" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/freshleaf/freshleaf14.webp"
                                        alt="FreshLeaf" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/freshleaf/freshleaf15.webp"
                                    alt="FreshLeaf" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/freshleaf/freshleaf16.webp" className="w-100" alt="FreshLeaf color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/freshleaf/freshleaf17.webp" alt="FreshLeaf font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text_light perform_pera">Abox Agency has been a game-changer for us. They've transformed our vision into reality with their exceptional website development and design services. Our tea business, FreshLeafs, now reaches tea lovers worldwide, thanks to the user-friendly site they created. The label and package design also perfectly represent our brand. We couldn't be happier with the results!</p>
                                    <h3 class="title_color padd_s text_shiska">Mr. Muneet Maheshwari</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">CEO of Freshleaf</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg radhey_sweets_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/radheysweet" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}