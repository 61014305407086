import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";
// import useScrollRedirect from "../hooks/useScrollRedirect";

export default function PortfolioFoneNetwork() {

    // useScrollRedirect('/portfolio/anandsweets')

    return (
        <>
            <Helmet>
                <title>Fone Network’s Mobile Blockchain Revolution | Success Stories: Web3 & Blockchain | aBox Agency</title>
                <meta name="title" content="Fone Network’s Mobile Blockchain Revolution | Success Stories: Web3 & Blockchain | aBox Agency" />
                <meta name="description" content="See how aBox and Fone Network are revolutionizing mobile technology with blockchain. Our collaboration created an informative and dynamic platform that highlights Fone Network’s innovative solutions." />
                <meta name="keywords" content="Fone Network, aBox, Web3, Blockchain, Mobile Technology, Web Development, React, Client Success Stories, Innovative Blockchain Solutions, Success Stories, Web Design, aBox Web Agency, Digital Transformation, SEO Optimization, UI/UX, Visual Identity, Informative Website, Technology Integration, Service-based Website, Blockchain for Mobile, Online Branding, Creative Web Solutions" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/fone-network" />
            </Helmet>

            <Header />

            <div className="page_bg bg_fone">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name fone_text2_bg omsweets_text">Fone Network</p>
                                </div>
                                <div className="w-100">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name fone_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">
                                        Fone Network <br />
                                        <span className="fone_title">
                                            Leading the Mobile Blockchain Revolution
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white mb-3">Fone Network is at the forefront of bringing blockchain technology to mobile. They needed a powerful online presence to showcase their innovative services. That’s where we, aBox, stepped in. We crafted a dynamic and informative website using React, designed to educate and inform visitors about Fone Network’s groundbreaking solutions. Today, Fone Network’s site stands as a beacon of their cutting-edge approach in the Web3 and blockchain space.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.fone.dev/" target="_blank" className="d-flex align-items-center fone_title">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="fone_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Website Development</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Visual Identity</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="fone_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Web3 and Blockchain Technology</li>
                                                <li>Website</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_fone_logo">
                                <img src="/assets/image/portfolios/fone/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/fone/fone1.webp" className="w-100" alt="Fone Network" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title fone_title">Elevating Fone Network’s Digital Footprint</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        Our dedicated team at aBox transformed Fone Network’s online presence with a sleek, responsive website. We focused on creating an engaging user experience that effectively communicates their pioneering blockchain solutions for mobile technology.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/fone/fone2.webp"
                                        alt="Fone Network" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/fone/fone3.webp"
                                            alt="Fone Network" className="img-fluid w-100" />
                                    </div>
                                    <img src="/assets/image/portfolios/fone/fone4.webp"
                                        alt="Fone Network" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/fone/fone5.webp"
                                        alt="Fone Network" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/fone/fone6.webp"
                                            alt="Fone Network" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/fone/fone7.webp"
                                            alt="Fone Network" className="img-fluid mt_20 w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 mt_20 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/fone/fone8.webp"
                                        alt="Fone Network" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title fone_title">Innovative Solutions Meet Informative Design</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Fone Network’s new website blends innovative technology with clear, concise information. Dive into <a href="https://www.fone.dev/" target="_blank" className="official_link omsweets_text">www.fone.dev</a>  to discover how they’re leveraging blockchain to transform mobile connectivity. Explore their services and see how they’re setting new standards in the industry.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/fone/fone9.webp"
                                        alt="Fone Network" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg gaps_sweetly mt_20 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/fone/fone10.webp"
                                        alt="Fone Network" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/fone/fone11.webp"
                                        alt="Fone Network" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent mt_20">
                                <img src="/assets/image/portfolios/fone/fone12.webp"
                                    alt="Fone Network" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title fone_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/fone/fone13.webp" className="w-100" alt="Fone Network color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/fone/fone14.webp" alt="Fone Network font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text-white perform_pera">aBox has crafted an informative and dynamic website that effectively communicates our blockchain solutions for mobile technology. Their innovative approach and attention to detail have set a new standard for our digital presence.</p>
                                    <h3 class="title_color padd_s fone_title">Fone Network</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Fone Network</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg repeat_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/repeat-gud" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}