import React, { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router';

export default function ContactForm() {

    const [formData, setFormData] = useState({ name: '', email: '', message: '' })
    const [validations, setValidations] = useState({ name: false, email: false, message: false })
    const [open, setOpen] = useState(false)

    const navigate = useNavigate();

    const handleChange = async (e) => {
        const { name, value } = e.target
        setValidations({ ...validations, [name]: false })
        setFormData({ ...formData, [name]: value })
    }

    const onSubmit = async (e) => {
        if (!formData['name']) {
            setValidations({ ...validations, name: true })
            return
        }
        if (!formData['email'] || !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email))) {
            setValidations({ ...validations, email: true })
            return
        }
        if (!formData['message']) {
            setValidations({ ...validations, message: true })
            return
        }

        navigate('/thank-you')

        try {
            await axios.post('https://formsubmit.co/ajax/65d554b105dcb5e0977b45ca2c6a2df7',
                {
                    _subject: 'Inquiry from Contact Form Component',
                    _template: 'table',
                    name: formData.name,
                    email: formData.email,
                    message: formData.message
                })
            setOpen(true)
            setFormData({ name: '', email: '', message: '' })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className="section_detail letstalk_section pb-0">
                <div className="container">
                    <div className="row border_bottom">
                        <div className="col-lg-6 mb-lg-0 mb-4">
                            <h2 className="testimonial_title text-white mb-0">Get a quote <br className="d-lg-block d-none" /><span className="contact_light">It all starts here.</span></h2>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="mb-2 position-relative">
                                        <input
                                            type="text"
                                            className="form-control contact_form"
                                            aria-describedby="emailHelp"
                                            placeholder="Name"
                                            onChange={handleChange}
                                            value={formData.name}
                                            name='name'
                                        />
                                        {validations.name && <div className="validations_text">Please enter name</div>}
                                    </div>
                                </div>
                                <div className="col-lg-6 ps-lg-0">
                                    <div className="mb-2 position-relative">
                                        <input
                                            type="email"
                                            className="form-control contact_form"
                                            aria-describedby="emailHelp"
                                            placeholder="Email address"
                                            onChange={handleChange}
                                            value={formData.email}
                                            name='email'
                                        />
                                        {validations.email && <div className="validations_text">{formData.email ? 'Please enter correct Email' : 'Please enter Email'}</div>}
                                    </div>
                                </div>
                                <div className="col-12 contact_detail">
                                    <div className="mb-4 position-relative">
                                        <textarea
                                            className="form-control contact_form h-100 border_20 ps-4"
                                            rows={6}
                                            defaultValue={""}
                                            placeholder="Write your message here"
                                            value={formData.message}
                                            name='message'
                                            onChange={handleChange}
                                        ></textarea>
                                        {validations.message && <div className="validations_text">Please enter message</div>}
                                    </div>
                                </div>
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <div>
                                        <p className="contact_light">Hate Contact form?</p>
                                        <a className="mail_a" href="mailto:hi@abox.agency" target="_blank">hi@abox.agency</a>
                                    </div>
                                    <div className="d-inline-block">
                                        <button onClick={() => onSubmit()} type="submit" className="btn submit_btn">Submit
                                            <svg className="ms-2 submit_arrow" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                                <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#222222" />
                                            </svg>
                                            <svg className="ms-2 submit_hover" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                                <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#EFEBE7" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Modal show={open} onHide={() => setOpen(false)} centered className="thankyou">
                <Modal.Body>
                    <h4>Thank you! <br /> for connecting us</h4>
                    <Button variant="secondary" onClick={() => setOpen(false)} className="thankyou_btn">
                        Close
                    </Button>
                </Modal.Body>
            </Modal> */}
        </>
    )
}