import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
// import useScrollRedirect from "../hooks/useScrollRedirect";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioChhappanbhog() {

    // useScrollRedirect('/portfolio/prakruti')

    return (
        <>
            <Helmet>
                <title>Client Work Success Story | Sweet Growth: Patel's Chhappanbhog Sweets | Box Web Agency</title>
                <meta name="title" content="Client Work Success Story | Sweet Growth: Patel's Chhappanbhog Sweets | Box Web Agency" />
                <meta name="description" content=" Discover the success story of Pate's Chhappanbhog Sweets and the sweet growth achieved through Box Web Agency's expert services. Explore how our collaboration transformed their online presence and business growth." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Client Work Success Story, Sweet Growth, Pate's Chhappanbhog Sweets Success, Box Web Agency, Online Presence Transformation, Web Agency Collaboration, Business Growth, Sweet Shop Success, Client Testimonial, Expert Services, Creative Solutions, Online Success Story, E-commerce Growth, Client Success Showcase, Sweet Store Website, Client-Centric Solutions, Business Transformation, Online Sweet Store, Web Design Showcase, Success Through Collaboration, Web Agency Excellence, Sweet Shop Branding, Web Impact, Box Client Stories" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/chhappanbhog" />
            </Helmet>

            <Header />

            <div className="page_bg chhappanbhog_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name chhapanbhog_text2_bg chhapanbhog_text2">Chhappanbhog</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1690894251.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name chhapanbhog_text2_bg chhapanbhog_text2 writing_mod">Published in 2023</p>
                                    <h1 className="services_title text-white mb-0"><span >Patel's Chhappanbhog</span> <br /> <span className="chhappan_title">Savoring the Essence of Authentic Indian Cuisine</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white mb-0">Offering a wide variety of regional, ready-to-eat Indian cuisine is Chhappanbhog's specialty. Although they have successfully provided services to Indian Railways, their goal is to increase the awareness of their brand both domestically and globally. They came to aBox with the requirement for an e-commerce website in order to accomplish this goal. Web design, development, UI/UX, graphic services, and more were all included in aBox's all-inclusive Shopify design. As a result, they have developed a complete Shopify website that offers their culinary services throughout India.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://patelschhappanbhog.com/" target="_blank" className="d-flex align-items-center chhappan_title">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="chhappan_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>3D Render</li>
                                                <li>Web Design</li>
                                                <li>Development</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="chhappan_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Ready-to-eat Foods</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="chhappan_banner bg_banner d-flex justify-content-center align-items-center">
                                <img src="/assets/image/product/chhapanbhog/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/product/chhapanbhog/product.webp" className="w-100" alt="chhapanbhog" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Crafting a Delicious Virtual Experience</h2>
                                    <p className="services_pera text-white font_regular mb-0">We immersed ourselves in Patel's Chhappanbhog's culinary traditions and values. Our design experts meticulously crafted a visually appealing website, tantalizing visitors with appetizing imagery and a user-friendly interface. The website's color palette and layout drew inspiration from the vibrant spices and ingredients of Indian cuisine, creating a sense of warmth and authenticity.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog1.webp"
                                            alt="chhappanbhog" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog2.webp"
                                            alt="chhappanbhog" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog3.webp"
                                            alt="chhappanbhog" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog4.webp"
                                            alt="chhappanbhog" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/product/chhapanbhog/chappan_digital.webp" alt="chhappanbhog" className="w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect chhappanbhog_product_bg">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog5.webp"
                                        alt="chhappanbhog" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog6.webp"
                                        alt="chhappanbhog" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog7.webp"
                                        alt="chhappanbhog" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Balancing Tradition and Innovation</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">The website's UI/UX design achieved the perfect fusion of tradition and modernity. It showcased Patel's Chhappanbhog's deep culinary roots while incorporating cutting-edge web design techniques, delivering an engaging and memorable online journey.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog8.webp"
                                        alt="chhappanbhog" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog9.webp"
                                        alt="chhappanbhog" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog10.webp"
                                        alt="chhappanbhog" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog11.webp"
                                        alt="chhappanbhog" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/chhappanbhog/chhappanbhog12.webp"
                                    alt="chhappanbhog" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title chhappan_platte">Color Palette</h3>
                            <div>
                                <img src="/assets/image/product/chhapanbhog/chhapan_plette.webp" className="w-100" alt="chhapanbhog color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/product/chhapanbhog/slabien.webp" alt="chhapanbhog font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text-white perform_pera">Working with the Box Agency has been an absolute pleasure. They perfectly captured the essence of our brand while designing a visually stunning website. Their technical prowess and dedication to our vision have resulted in an exceptional online shopping experience for our customers. We highly recommend the Box Agency for their professionalism and their ability to exceed expectations.”</p>
                                    <h3 class="chhappan_title padd_s">Amin Harshil</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Patel's Chhappanbhog</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg prakruti_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/prakruti" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}