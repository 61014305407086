import React from 'react'

export default function DataNotFound() {
    return (
        <>
            <div className="Loader_center">
                <span class="loader-not">NotFound</span>
            </div>
        </>
    )
}
