import React, { useEffect, useRef } from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import PortfolioHorizontalSlider from "../component/PortfolioHorizontalSlider";

gsap.registerPlugin(ScrollTrigger);

export default function ServiceUiUx() {

    const section1Ref = useRef(null);
    const colLeftRef = useRef(null);

    useEffect(() => {
        if (window.innerWidth > 1024) {
            function raf(time) {
                ScrollTrigger.update();
                requestAnimationFrame(raf);
            }

            requestAnimationFrame(raf);

            const section1 = section1Ref.current;
            const colLeft = colLeftRef.current;
            const timeline = gsap.timeline({ paused: true });

            timeline.fromTo(
                colLeft,
                { y: 0 },
                { y: '170vh', duration: 1, ease: 'none' },
                0
            );

            ScrollTrigger.create({
                animation: timeline,
                trigger: section1,
                start: 'top top',
                end: 'bottom center',
                scrub: true,
            });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>UI/UX Services to Revolutionize Your Business Design | Box Web Agency</title>
                <meta name="title" content="UI/UX Services to Revolutionize Your Business Design | Box Web Agency" />
                <meta name="description" content="Experience a design revolution with UI/UX services from Box Web Agency. Our expert team transforms user experiences, enhancing your business design for optimal engagement and success." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, UI/UX Services, Business Design Revolution, User Experience Enhancement, Web Agency UI/UX, Design Transformation, User-Centric Design, Expert UI/UX Solutions, Creative User Experience, Business Design Upgrade, Design Revolution, Web Design Innovation, Optimal User Engagement, UI/UX Expertise, Web Design Enhancement, User-Centered Solutions, UI/UX Transformation, Web Experience Revolution, Design Excellence, Web Agency Services, User Interface Design, User Experience Strategy, Design Success, User-Centric Approach, UI/UX Collaboration, Design for Engagement" />
                <link rel="canonical" href="https://www.abox.agency/services/uiux" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="header_padding">
                <div className="section_detail">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-7 sec_padding">
                                <h1 className="service_heading">Unparalleled UI/UX</h1>
                                <p className="seo_p">Discover the magic of UI/UX! At aBox agency, we create captivating user experiences that boost engagement, enhance brand loyalty, and drive conversions. Elevate your digital presence with our expertly crafted designs and seamless interfaces. Success awaits!</p>
                            </div>
                            <div className="col-lg-5 pe-0 text-end ps-lg-3 ps-0">
                                <div>
                                    <img src="/assets/image/service/uiux.webp" alt="uiux" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* seo detail section */}
            <div className="section_detail seo_section">
                <div className="container">
                    <div className="row padding_bottom border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Transforming User Experiences with Unmatched Design</h2>
                        </div>
                        <div className="col-xl-6">
                            <p className="seo_content">After Discussion of your requirements Our expert team combines creativity and usability to deliver seamless digital experiences that captivate users, enhance brand value, and drive business growth. Elevate your online presence with our unparalleled design expertise.</p>
                        </div>
                    </div>
                    <div className="row feature_padding border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">The best features We provide</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex flex-wrap">
                                <p className="feature_p">User-friendly interface</p>
                                <p className="feature_p">Intuitive navigation</p>
                                <p className="feature_p">Attractive visuals</p>
                                <p className="feature_p">Consistent branding</p>
                                <p className="feature_p"> Responsive layout</p>
                                <p className="feature_p">Clear call-to-action buttons</p>
                                <p className="feature_p">Personalization options</p>
                                <p className="feature_p">Efficient search functionality</p>
                            </div>
                        </div>
                    </div>
                    <div className="row feature_padding pb-0">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Why aBox?</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Creativity</h3>
                                    <p className="seo_content mb-0 font_light">Our team thrives on generating fresh and innovative ideas, constantly pushing the boundaries of design. By thinking outside the box, we create unique and captivating user experiences that differentiate our clients' products and leave a lasting impact.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">User-Centric Approach</h3>
                                    <p className="seo_content mb-0 font_light">We place paramount importance on the needs and preferences of end-users. By understanding their behaviors, motivations, and pain points, we create designs that are intuitive, engaging, and tailored to provide a seamless user experience.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Timely Delivery</h3>
                                    <p className="seo_content mb-0 font_light">We prioritize timely delivery. We understand the importance of meeting project timelines and ensuring that our clients receive their deliverables promptly. Our commitment to punctuality guarantees a smooth and efficient experience for our clients every time.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Cutting-Edge Tools</h3>
                                    <p className="seo_content mb-0 font_light">We utilize industry-leading UI/UX design software such as Adobe XD, Sketch, Figma, and InVision to create innovative and interactive prototypes, wireframes, and visual designs. These tools enable us to stay ahead of the curve and deliver outstanding results.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* detail section */}
            <div className="section_detail image_section detail_section uiux_section" ref={section1Ref}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" ref={colLeftRef}>
                            <h2 className="service_h2 title_margin">Enhance the user experience <br className="d-md-block d-none" />
                                with our top-notch UI/UX services</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">U/1</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">User Research</h3>
                                    <p className="services_p">Prioritizing user research to gain valuable insights into the target audience By understanding their needs, preferences, and behaviors, we ensure that our designs meet their expectations. Through methods like surveys, interviews, and analytics, we gather data-driven information that guides our decision-making process. This user-centric approach allows us to create interfaces that are tailored to your users, resulting in a delightful and engaging user experience.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">U/2</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Ideation</h3>
                                    <p className="services_p">Our talented team engages in a collaborative brainstorming process to generate innovative ideas for your UI/UX design. We explore diverse design possibilities, sketching out concepts that align with your brand and target audience. By thinking outside the box, we ensure that your interface stands out, captures attention, and provides a memorable user experience. Our goal is to create a design that resonates with your users and achieves your business objectives.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">U/3</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Intuitive Navigation</h3>
                                    <p className="services_p">Navigating through your website should be a breeze for your visitors. We meticulously design user interfaces that prioritize intuitive navigation, making it effortless for users to find what they're looking for. By streamlining the user journey and implementing logical information architecture, we enhance the overall satisfaction of your users and increase their chances of converting into loyal customers.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">U/4</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Consistent Branding</h3>
                                    <p className="services_p">A brand's identity is a crucial aspect of its online presence. Our UI/UX solutions ensure consistent branding across all platforms, reinforcing your brand's message and increasing brand recognition. By incorporating your brand colors, typography, and visual elements consistently throughout your website, we create a cohesive and memorable brand experience for your users.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">U/5</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Mobile Optimization</h3>
                                    <p className="services_p">In this mobile-centric era, having a mobile-optimized website is essential. Our expert designers and developers ensure your website is fully responsive and optimized for a seamless user experience on all devices, regardless of screen size. By leveraging responsive design techniques, we guarantee that your website looks and functions flawlessly on smartphones, tablets, and desktops alike, capturing the attention of your mobile audience.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">U/6</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Data-Driven Insights</h3>
                                    <p className="services_p">We believe in data-backed decision-making. Our team utilizes user analytics and testing tools to gather valuable insights about user behavior, preferences, and pain points. By analyzing this data, we uncover areas for improvement, identify usability issues, and optimize your website to deliver the best possible user experience.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">U/7</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Icons and Designs</h3>
                                    <p className="services_p">At aBox, we carefully select icons and designs that enhance the user experience. Our team of skilled designers considers factors like clarity, consistency, and visual appeal when choosing icons. We prioritize intuitive and user-friendly designs that align with your brand identity. By incorporating visually engaging elements, we create interfaces that communicate effectively and facilitate seamless interactions, resulting in a delightful user experience.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin mb-0">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">U/8</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Wireframing and Prototyping</h3>
                                    <p className="services_p">In the wireframing phase, we create simplified blueprints that outline the structure and functionality of your interface, ensuring a clear visual representation of the layout. With prototyping, we transform these wireframes into interactive models, allowing you to test and validate the user experience before proceeding with development, resulting in a well-designed and user-friendly final product.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PortfolioHorizontalSlider />

            <LetsTalk />

            <Footer />
        </>
    );
}