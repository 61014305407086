import React from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import { Link, useSearchParams } from "react-router-dom";

export default function Portfolio() {

    const [searchParams, setSearchParams] = useSearchParams()
    const activeTab = searchParams.get('tab') || 'all'

    // const getTooptip = (id) => {
    //     let toolTip = document.getElementById(id);
    //     window.addEventListener('mousemove', function (e) {
    //         let x = e.clientX,
    //             y = e.clientY;
    //         toolTip.style.top = (y + 15) + 'px';
    //         toolTip.style.left = (x + 15) + 'px';
    //     });
    // }

    function changeColor(...args) {
        document.getElementById("portfolio").style.backgroundColor = args[0];
        const elements = document.querySelectorAll(`.color_light.change-color`)
        const tabsElement = document.querySelectorAll(`.tabs`)
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.color = args[1]
        }
        for (let i = 0; i < tabsElement.length; i++) {
            tabsElement[i].classList.add('#EFEBE750')
        }
    }

    const portfolioLeft = [
        {
            href: '/portfolio/anandsweets',
            onMouseOverColor: { bg: '#1B1830', text: '#9C8EFF' },
            imgSrc: '/assets/image/anand_sweets.webp',
            imgAlt: 'anand sweets',
            projectName: 'Anand Sweets',
            projectInfoOne: 'Traditional Indian Sweets: A ',
            projectInfoTwo: 'Taste of Royal India',
            descriptionOne: 'Shopify, UI/UX, and SEO',
        },
        {
            href: '/portfolio/yogabar',
            onMouseOverColor: { bg: '#0C052B', text: '#AF5FFF' },
            imgSrc: '/assets/image/yogabar.webp',
            imgAlt: 'yogabar',
            projectName: 'YogaBar',
            projectInfoOne: 'Healthy protein-based foods',
            descriptionOne: 'Shopify, UI/UX, Graphic Designing,',
            descriptionTwo: 'Site Maintenance',
        },
        {
            href: '/portfolio/chhappanbhog',
            onMouseOverColor: { bg: '#171C26', text: '#8DC0FF' },
            imgSrc: '/assets/image/chappanbhog.webp',
            imgAlt: 'chappanbhog',
            projectName: 'Patel’s Chhappan Bhog',
            projectInfoOne: 'Traditional Indian',
            projectInfoTwo: 'Ready-to-eat cuisine',
            descriptionOne: 'Shopify, UI/UX, Graphic Designing',
        },
        {
            href: '/portfolio/givernutritions',
            onMouseOverColor: { bg: '#2E170A', text: '#FFD4AC' },
            imgSrc: '/assets/image/giver.webp',
            imgAlt: 'giver',
            projectName: 'Giver Nutritions',
            projectInfoOne: 'A health supplement company focused on Mental Health',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/hyfun',
            onMouseOverColor: { bg: '#102602', text: '#DEFBCC' },
            imgSrc: '/assets/image/hyfun.webp',
            imgAlt: 'hyfun',
            projectName: 'HyFun Foods',
            projectInfoOne: '100% veg. frozen snacks. Made with finest vegetables.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/thenga',
            onMouseOverColor: { bg: '#231302', text: '#EDEAE0' },
            imgSrc: '/assets/image/thenga.webp',
            imgAlt: 'thenga',
            projectName: 'Thenga Coco',
            projectInfoOne: 'Transforming coconut wastes into eco-friendly treasures.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/sportsaber',
            onMouseOverColor: { bg: '#260C04', text: '#EF6940' },
            imgSrc: '/assets/image/sport_saber.webp',
            imgAlt: 'sport saber',
            projectName: 'Sport Saber',
            projectInfoOne: 'Elite gear, vibrant community, and charitable support.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/ubar',
            onMouseOverColor: { bg: '#401603', text: '#F5D8CB' },
            imgSrc: '/assets/image/ubar.webp',
            imgAlt: 'ubar',
            projectName: 'Ubar',
            projectInfoOne: 'Elevate Wellness with Premium Health Supplements.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/radheysweet',
            onMouseOverColor: { bg: '#04272E', text: '#FFFBD2' },
            imgSrc: '/assets/image/radhey_sweets.webp',
            imgAlt: 'radhey sweets',
            projectName: 'Radhey Sweets',
            projectInfoOne: 'Authentic Flavours, Irresistible Indian Sweets and Namkeen.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/maa-and-baby',
            onMouseOverColor: { bg: '#014263', text: '#E8FAFF' },
            imgSrc: '/assets/image/maababy.webp',
            imgAlt: 'maa & baby',
            projectName: 'Maa & Baby',
            projectInfoOne: 'Baby Products for parent\'s Precious Moments',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/ekkibekki',
            onMouseOverColor: { bg: '#07313C', text: '#E8FAFF' },
            imgSrc: '/assets/image/ekkibekki.webp',
            imgAlt: 'Ekkibekki',
            projectName: 'Ekkibekki',
            projectInfoOne: 'Wallpaper designs for artful interiors',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/teatoxlife',
            onMouseOverColor: { bg: '#0D2318', text: '#E8FAFF' },
            imgSrc: '/assets/image/teatox.webp',
            imgAlt: 'Tea Tox Life',
            projectName: 'Tea Tox Life',
            projectInfoOne: 'Natural Herbal Wellness for Healthy Living',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/greenfay',
            onMouseOverColor: { bg: '#2E0B01', text: '#E8FAFF' },
            imgSrc: '/assets/image/greenfay.webp',
            imgAlt: 'Greenfay',
            projectName: 'Greenfay',
            projectInfoOne: 'Premium potato farming with community empowerment.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/naturare',
            onMouseOverColor: { bg: '#011A0D', text: '#E8FAFF' },
            imgSrc: '/assets/image/thumbnailimage/naturare.webp',
            imgAlt: 'naturare',
            projectName: 'Naturare',
            projectInfoOne: 'Transforming wellness and empowering lives naturally.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/neoroots',
            onMouseOverColor: { bg: '#14332C', text: '#E8FAFF' },
            imgSrc: '/assets/image/thumbnailimage/neoroots.webp',
            imgAlt: 'neoroots',
            projectName: 'Neo Roots',
            projectInfoOne: 'Custom greenery for indoor and outdoor spaces.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/uppercase',
            onMouseOverColor: { bg: '#063541', text: '#D1F2F8' },
            imgSrc: '/assets/image/uppercase.webp',
            imgAlt: 'Uppercase',
            projectName: 'Uppercase',
            projectInfoOne: 'Eco-Friendly Travel Gear with Stylish Design.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/healing-hands',
            onMouseOverColor: { bg: '#042C2F', text: '#AAF9FF' },
            imgSrc: '/assets/image/healign.webp',
            imgAlt: 'Healing Hands',
            projectName: 'Healing Hands',
            projectInfoOne: 'Plant-based meds for Piles and Constipation Relief',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/codifi',
            onMouseOverColor: { bg: '#311367', text: '#A38DC9' },
            imgSrc: '/assets/image/codifi.webp',
            imgAlt: 'Codifi',
            projectName: 'Codifi',
            projectInfoOne: 'crafting cutting-edge financial solutions for Indian markets.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/kutum-ayurveda',
            onMouseOverColor: { bg: '#975C24', text: '#DCB793' },
            imgSrc: '/assets/image/kutum.webp',
            imgAlt: 'Kutum Ayurveda',
            projectName: 'Kutum Ayurveda',
            projectInfoOne: 'Pure Ayurvedic Care for Mind, Body & Spirit.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/fone-network',
            onMouseOverColor: { bg: '#07083A', text: '#989AF4' },
            imgSrc: '/assets/image/fone.webp',
            imgAlt: 'Fone Network',
            projectName: 'Fone Network',
            projectInfoOne: 'Mobile blockchain & cryptocurrency enabling NFT creation, and staking.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/nurdle',
            onMouseOverColor: { bg: '#1E2B3C', text: '#959EAB' },
            imgSrc: '/assets/image/nurdle.webp',
            imgAlt: 'Nurdle',
            projectName: 'Nurdle',
            projectInfoOne: `Colorful, Comfortable Kids' Underwear with Surprise Gifts.`,
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/technosport',
            onMouseOverColor: { bg: '#1B1B1B', text: '#888888' },
            imgSrc: '/assets/image/technosport.webp',
            imgAlt: 'Technosport',
            projectName: 'Techno Sport',
            projectInfoOne: 'Stylish Athleisure Wear, Uniting Fashion and Function.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/myoa-cookies',
            onMouseOverColor: { bg: '#29120F', text: '#F5DFBB' },
            imgSrc: '/assets/image/myoa_cookies.webp',
            imgAlt: 'Myoa Cookies',
            projectName: 'Myoa Cookies',
            projectInfoOne: 'Luxury cookies with premium ingredients - baked fresh.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/bontrue-furniture',
            onMouseOverColor: { bg: '#280E0A', text: '#AF827B' },
            imgSrc: '/assets/image/bontrue_furniture.webp',
            imgAlt: 'Bontrue Furniture',
            projectName: 'Bontrue Furniture',
            projectInfoOne: 'Stylish and functional furniture for modern living spaces.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        }
    ];

    const portfolioRight = [
        {
            href: '/portfolio/dr-vaidya',
            onMouseOverColor: { bg: '#083544', text: '#BDF0FF' },
            imgSrc: '/assets/image/dr_vaidiya.webp',
            imgAlt: 'dr vaidyas',
            projectName: 'Dr. Vaidyas',
            projectInfoOne: 'Ayurvedic Health and Stamina Supplements',
            descriptionOne: 'Shopify, UI/UX, Graphic Designing',
        },
        {
            href: '/portfolio/nourysh',
            onMouseOverColor: { bg: '#01191F', text: '#E1E4FF' },
            imgSrc: '/assets/image/nourysh.webp',
            imgAlt: 'nourysh',
            projectName: 'Nourysh',
            projectInfoOne: 'Nutritional Formulations',
            projectInfoTwo: 'for Health',
            descriptionOne: 'Packaging Designing, Shopify,',
            descriptionTwo: 'UI/UX, Visual Identity',
        },
        {
            href: '/portfolio/bayla',
            onMouseOverColor: { bg: '#2C3001', text: '#EFFFBE' },
            imgSrc: '/assets/image/bayla.webp',
            imgAlt: 'bayla',
            projectName: 'Bayla Skins',
            projectInfoOne: 'Natural and Science-Backed Skincare',
            descriptionOne: 'Shopify, UI/UX, Visual Identity',
        },
        {
            href: '/portfolio/prakruti',
            onMouseOverColor: { bg: '#022816', text: '#90E3BD' },
            imgSrc: '/assets/image/prakruti.webp',
            imgAlt: 'prakruti',
            projectName: 'Prakruti Pure Herbs',
            projectInfoOne: 'Best Ayurvedic Medicine, Health supplements,',
            projectInfoTwo: 'Skin & Hair Care',
            descriptionOne: 'Shopify, UI/UX, Visual Identity',
        },
        {
            href: '/portfolio/hamdard',
            onMouseOverColor: { bg: '#2E0304', text: '#FBE5E6' },
            imgSrc: '/assets/image/hamdard.webp',
            imgAlt: 'hamdard',
            projectName: 'Hamdard',
            projectInfoOne: "India's leading unani medicine brand.",
            descriptionOne: 'Shopify, UI/UX, Visual Identity',
        },
        {
            href: '/portfolio/shistaka',
            onMouseOverColor: { bg: '#241B03', text: '#F2E2BA' },
            imgSrc: '/assets/image/shistaka.webp',
            imgAlt: 'shistaka',
            projectName: 'Shistaka',
            projectInfoOne: 'Blending Ayurvedic wisdom with modern wellness philosophy.',
            descriptionOne: 'Shopify, UI/UX, Visual Identity',
        },
        {
            href: '/portfolio/dlfilms',
            onMouseOverColor: { bg: '#171717', text: '#F5F1EB' },
            imgSrc: '/assets/image/dlfilm.webp',
            imgAlt: 'dl films',
            projectName: 'DL Films',
            projectInfoOne: 'Explore Life Through Lens Photography Experts',
            descriptionOne: 'Shopify, UI/UX, Visual Identity',
        },
        {
            href: '/portfolio/freshleaf',
            onMouseOverColor: { bg: '#212721', text: '#F5FAE2' },
            imgSrc: '/assets/image/freshleaf.webp',
            imgAlt: 'freshleaf',
            projectName: 'FreshLeaf',
            projectInfoOne: 'Pure, handpicked teas for ultimate freshness.',
            descriptionOne: 'Shopify, UI/UX, Visual Identity',
        },
        {
            href: '/portfolio/healthoxide',
            onMouseOverColor: { bg: '#052F46', text: '#E8FAFF' },
            imgSrc: '/assets/image/healthoxide.webp',
            imgAlt: 'healthoxide',
            projectName: 'Healthoxide',
            projectInfoOne: 'Elevate Your Health and Fitness Journey',
            descriptionOne: 'Shopify, UI/UX, Visual Identity',
        },
        {
            href: '/portfolio/gogrub',
            onMouseOverColor: { bg: '#031929', text: '#E8FAFF' },
            imgSrc: '/assets/image/gogrub.webp',
            imgAlt: 'go grub',
            projectName: 'Go Grub',
            projectInfoOne: 'Discover Delicious Wholesome Pantry Picks',
            descriptionOne: 'Shopify, UI/UX, Visual Identity',
        },
        {
            href: '/portfolio/mypro',
            onMouseOverColor: { bg: '#161616', text: '#E8FAFF' },
            imgSrc: '/assets/image/mypro.webp',
            imgAlt: 'My Pro',
            projectName: 'My Pro Sport Nutritions',
            projectInfoOne: 'Fueling fitness, wellness, and peak performance',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/aumex',
            onMouseOverColor: { bg: '#050121', text: '#E8FAFF' },
            imgSrc: '/assets/image/aumex.webp',
            imgAlt: 'Aumex',
            projectName: 'Aumex',
            projectInfoOne: 'Revitalize Intimate Wellness with Care',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/paperclip-co',
            onMouseOverColor: { bg: '#040328', text: '#E8FAFF' },
            imgSrc: '/assets/image/thumbnailimage/paperclip.webp',
            imgAlt: 'paperclip & co',
            projectName: 'Paperclip & Co',
            projectInfoOne: 'Innovative Campaigns, Unleashing Social Media Power.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/fast-n-fry',
            onMouseOverColor: { bg: '#130F0F', text: '#E8FAFF' },
            imgSrc: '/assets/image/thumbnailimage/fastfry.webp',
            imgAlt: 'fast n fry',
            projectName: 'Fast n Fry',
            projectInfoOne: 'Premium Potato & Frozen Snacks Manufacturer.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/within-beauty',
            onMouseOverColor: { bg: '#412382', text: '#E3DCFF' },
            imgSrc: '/assets/image/within.webp',
            imgAlt: 'Within Beauty',
            projectName: 'Within Beauty',
            projectInfoOne: 'Skincare products with natural, cruelty-free ingredients.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/om-sweets',
            onMouseOverColor: { bg: '#C01F25', text: '#FBF4EC' },
            imgSrc: '/assets/image/omsweets.webp',
            imgAlt: 'om sweets',
            projectName: 'Om Sweets & Snacks',
            projectInfoOne: 'Authentic snacks and sweets for every occasion.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/yo-gathiya',
            onMouseOverColor: { bg: '#7C0A21', text: '#FCEED0' },
            imgSrc: '/assets/image/yogathiya.webp',
            imgAlt: 'yo gathiya',
            projectName: 'Yo Gathiya',
            projectInfoOne: 'Savor tradition with premium Indian Namkeen and snacks.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/terahash-solutions',
            onMouseOverColor: { bg: '#0F0F0F', text: '#A4A4A4' },
            imgSrc: '/assets/image/terahash.webp',
            imgAlt: 'Terahash Solutions',
            projectName: 'Terahash Solution',
            projectInfoOne: 'Best Partner for Profitable and Reliable Crypto Mining.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/arospice',
            onMouseOverColor: { bg: '#204F34', text: '#BED0C6' },
            imgSrc: '/assets/image/arospice.webp',
            imgAlt: 'Arospice',
            projectName: 'Arospice',
            projectInfoOne: 'Pure essence of tradition, passion, and magic in every product.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/repeat-gud',
            onMouseOverColor: { bg: '#131313', text: '#FCEED0' },
            imgSrc: '/assets/image/repeat_gud.webp',
            imgAlt: 'Repeat Gud',
            projectName: 'Repeat Gud',
            projectInfoOne: 'Pure, Nutrient-Packed Sauces Delighting Taste Buds and Nourishing.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/aurolab',
            onMouseOverColor: { bg: '#005268', text: '#7FB3C0' },
            imgSrc: '/assets/image/aurolab.webp',
            imgAlt: 'AuroLab',
            projectName: 'AuroLab',
            projectInfoOne: 'A global leader in ophthalmic solutions, enabling better vision for all.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/westerville',
            onMouseOverColor: { bg: '#202C39', text: '#99BEE2' },
            imgSrc: '/assets/image/westerville.webp',
            imgAlt: 'Westerville',
            projectName: 'Westerville',
            projectInfoOne: 'Expert dental care for your dream smile.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/sparkfusion',
            onMouseOverColor: { bg: '#151213', text: '#9E9E9E' },
            imgSrc: '/assets/image/sparkfusion.webp',
            imgAlt: 'sparkfusion',
            projectName: 'Spark Fusion',
            projectInfoOne: 'Premium fitness products for better health and strength.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        },
        {
            href: '/portfolio/bhagavad-gita',
            onMouseOverColor: { bg: '#451018', text: '#8D5F66' },
            imgSrc: '/assets/image/bhagavad_gita.webp',
            imgAlt: 'Bhagavad Gita',
            projectName: 'Bhagavad Gita',
            projectInfoOne: 'Original Vedic scriptures, books, and spiritual teachings.',
            descriptionOne: 'Packaging Designing, Shopify, UI/UX',
        }
    ]

    const ProjectOne = ({ href, onMouseOverColor, imgSrc, imgAlt, projectName, projectInfoOne, projectInfoTwo, descriptionOne, descriptionTwo }) => (
        <div className="anand-sweets">
            <Link
                to={href}
                className="project-link"
                onMouseOver={() => changeColor(onMouseOverColor.bg, onMouseOverColor.text)}
                onMouseOut={() => changeColor('#222', '#efebe7')}
            >
                <img src={imgSrc} loading="lazy" alt={imgAlt} className="w-100" />
                <div className="div-block-42">
                    <p className="project-name change-color color_light">{projectName}</p>
                    <div className="line dark_line" />
                </div>
                <h2 className="project-info change-color color_light">{projectInfoOne} <br className="d-lg-block d-none" /> {projectInfoTwo}</h2>
                <p className="paragraph-13 change-color color_light">{descriptionOne} <br className="d-lg-block d-none" /> {descriptionTwo} </p>
            </Link>
        </div>
    );

    const ProjectTwo = ({ href, onMouseOverColor, imgSrc, imgAlt, projectName, projectInfoOne, projectInfoTwo, descriptionOne, descriptionTwo }) => (
        <div className="anand-sweets">
            <Link
                to={href}
                className="project-link"
                onMouseOver={() => changeColor(onMouseOverColor.bg, onMouseOverColor.text)}
                onMouseOut={() => changeColor('#222', '#efebe7')}
            >
                <img src={imgSrc} loading="lazy" alt={imgAlt} className="w-100" />
                <div className="div-block-42">
                    <p className="project-name change-color color_light">{projectName}</p>
                    <div className="line dark_line" />
                </div>
                <h2 className="project-info change-color color_light">{projectInfoOne} <br className="d-lg-block d-none" /> {projectInfoTwo}</h2>
                <p className="paragraph-13 change-color color_light">{descriptionOne} <br className="d-lg-block d-none" /> {descriptionTwo}</p>
            </Link>
        </div>
    );


    return (
        <>
            <Helmet>
                <title>Portfolios | Transforming Visions into Reality | Delve into Our Work | Box Web Agency</title>
                <meta name="title" content="Portfolios | Transforming Visions into Reality | Delve into Our Work | Box Web Agency" />
                <meta name="description" content="Explore our portfolio showcasing how Box Web Agency turns visions into reality. Discover our transformative projects that highlight creativity and innovation in web design and development." />
                <meta name="keywords" content="Box, Shopify, E-coomerce, Web Agency, Portfolio, Web Development, Shopify store, WorkFolio, Showcase, Web Design Projects, Creative Development, Client Projects, Website Showcase, Digital Transformation, Design Innovation, Visual Creativity, Web Development Work, Online Solutions, Client Success Stories, Transformative Designs, Creative Showcase, Innovation in Web Design, Digital Portfolio, Realizing Visions, Client Transformations, Web Project Highlights, Innovative Web Solutions, Box Agency Portfolio, Web Design Excellence, Client-Centric Solutions, Success in, Web Development" />
                <link rel="canonical" href="https://www.abox.agency/portfolio" />
            </Helmet>

            <Header />

            {/* project section */}
            <div id="portfolio" className="section_detail default_bg portfolio_section">
                <div className="container">
                    <div className="portfolio_title">
                        <div className="row">
                            <div className="col-xl-10">
                                <p className="mb-1 font_medium color_light change-color">Projects</p>
                                <h1 className="portfolio_heading color_light change-color">Our design and development approach creates impactful, engaging brands and immersive digital experiences.</h1>
                            </div>
                        </div>
                    </div>

                    <ul className="nav nav-pills portfolio" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className={activeTab === 'all' ? "nav-link tabs active" : "nav-link tabs"}
                                // id="pills-all-tab"
                                // data-bs-toggle="pill"
                                // data-bs-target="#pills-all"
                                // type="button" 
                                // role="tab"
                                // aria-controls="pills-all"
                                // aria-selected="true"
                                onClick={() => setSearchParams({ 'tab': 'all' })}>
                                All
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={activeTab === 'e-commerce' ? "nav-link tabs active" : "nav-link tabs"}
                                // id="pills-e-commerce-tab"
                                // data-bs-toggle="pill"
                                // data-bs-target="#pills-ecommerce"
                                // type="button"
                                // role="tab"
                                // aria-controls="pills-ecommerce"
                                // aria-selected="false"
                                onClick={() => setSearchParams({ 'tab': 'e-commerce' })}>
                                E-commerce
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={activeTab === 'ui-design' ? "nav-link tabs active" : "nav-link tabs"}
                                // id="pills-ui-design-tab"
                                // data-bs-toggle="pill"
                                // data-bs-target="#pills-ui"
                                // type="button"
                                // role="tab"
                                // aria-controls="pills-ui"
                                // aria-selected="false"
                                onClick={() => setSearchParams({ 'tab': 'ui-design' })}>
                                UI Design
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={activeTab === 'packaging-design' ? "nav-link tabs active" : "nav-link tabs"}
                                // id="pills-packaging-tab"
                                // data-bs-toggle="pill"
                                // data-bs-target="#pills-packaging"
                                // type="button"
                                // role="tab"
                                // aria-controls="pills-packaging"
                                // aria-selected="false"
                                onClick={() => setSearchParams({ 'tab': 'packaging-design' })}>
                                Packaging Design
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={activeTab === 'branding' ? "nav-link tabs active" : "nav-link tabs"}
                                // id="pills-branding-tab"
                                // data-bs-toggle="pill"
                                // data-bs-target="#pills-branding"
                                // type="button"
                                // role="tab"
                                // aria-controls="pills-branding"
                                // aria-selected="false"
                                onClick={() => setSearchParams({ 'tab': 'branding' })}>
                                Branding
                            </button>
                        </li>
                    </ul>

                    <div className="tab-content" id="pills-tabContent">
                        {activeTab === "all" && (
                            <div className="tab-opacity" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                                <div className="div-block-40 flex-column">
                                    <div className="row">
                                        <div className="col-lg-6 space_right">
                                            <div className="projects me-0">
                                                {
                                                    portfolioLeft.map((i, index) =>
                                                        <ProjectOne key={index} {...i} />
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6 space_left">
                                            <div className="projects margin-top me-0">
                                                {
                                                    portfolioRight.map((i, index) =>
                                                        <ProjectTwo key={index} {...i} />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === "e-commerce" && (
                            <div className="tab-opacity" id="pills-ecommerce" role="tabpanel" aria-labelledby="pills-e-commerce-tab">
                                <div className="div-block-40 flex-column">
                                    <div className="row">
                                        <div className="col-lg-6 space_right">
                                            <div className="projects me-0">
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/anandsweets" className="project-link" onMouseOver={() => { changeColor('#1B1830', '#9C8EFF') }}
                                                        onMouseOut={() => {
                                                            changeColor('#222', '#efebe7')
                                                        }}>
                                                        <img src="/assets/image/anand_sweets.webp" loading="lazy" alt="anand sweets" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Anand Sweets</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Traditional Indian Sweets:<br className="d-lg-block d-none" /> A Taste of Royal India</h2>
                                                        <p className="paragraph-13 color_light change-color">Shopify, UI/UX, and SEO</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/yogabar" className="project-link" onMouseOver={() => { changeColor('#03063B', '#AF5FFF') }}
                                                        onMouseOut={() => {
                                                            changeColor('#222', '#efebe7')
                                                        }}>
                                                        <img src="/assets/image/yogabar.webp" loading="lazy" alt="yogabar" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">YogaBar</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Healthy protein-based foods</h2>
                                                        <p className="paragraph-13 color_light change-color">Shopify, UI/UX, Graphic Designing,<br className="d-lg-block d-none" /> Site Maintenance</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/chhappanbhog" className="project-link" onMouseOver={() => { changeColor('#1C222D', '#8DC0FF') }}
                                                        onMouseOut={() => {
                                                            changeColor('#222', '#efebe7')
                                                        }}>
                                                        <img src="/assets/image/chappanbhog.webp" loading="lazy" alt="chappanbhog" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Patel’s Chhappan Bhog</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Traditional indian <br className="d-lg-block d-none" /> Ready-to-eat cuisine</h2>
                                                        <p className="paragraph-13 color_light change-color">Shopify, UI/UX, Graphic Designing</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/givernutritions" className="project-link" onMouseOver={() => { changeColor('#3B2311', '#FFD4AC') }}
                                                        onMouseOut={() => {
                                                            changeColor('#222', '#efebe7')
                                                        }}>
                                                        <img src="/assets/image/giver.webp" loading="lazy" alt="giver" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Giver Nutritions</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">A health supplement <br className="d-lg-block d-none" /> company focused on <br className="d-lg-block d-none" /> Mental Health</h2>
                                                        <p className="paragraph-13 color_light change-color">Packaging Designing, Shopify, UI/UX</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 space_left">
                                            <div className="projects margin-top me-0">
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/dr-vaidya" className="project-link" onMouseOver={() => { changeColor('#2A2D0D', '#BDF0FF') }}
                                                        onMouseOut={() => {
                                                            changeColor('#222', '#efebe7')
                                                        }}>
                                                        <img src="/assets/image/dr_vaidiya.webp" loading="lazy" alt="dr vaidyas" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Dr. Vaidyas</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Ayurvedic Health and <br className="d-lg-block d-none" /> Stamina Supplements</h2>
                                                        <p className="paragraph-13 color_light change-color">Shopify, UI/UX, Graphic Designing</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/nourysh" className="project-link" onMouseOver={() => { changeColor('#532410', '#E1E4FF') }}
                                                        onMouseOut={() => {
                                                            changeColor('#222', '#efebe7')
                                                        }}>
                                                        <img src="/assets/image/nourysh.webp" loading="lazy" alt="nourysh" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Nourysh</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Nutritional Formulations <br className="d-lg-block d-none" /> for Health</h2>
                                                        <p className="paragraph-13 color_light change-color">Packaging Designing, Shopify, <br className="d-lg-block d-none" /> UI/UX, Visual Identity</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/bayla" className="project-link" onMouseOver={() => { changeColor('#2c3001', '#EFFFBE') }}
                                                        onMouseOut={() => {
                                                            changeColor('#222', '#efebe7')
                                                        }}>
                                                        <img src="/assets/image/bayla.webp" loading="lazy" alt="bayla" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Bayla Skins</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Natural and Science-<br className="d-lg-block d-none" /> Backed Skincare</h2>
                                                        <p className="paragraph-13 color_light change-color">Shopify, UI/UX, Visual Identity</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === "ui-design" && (
                            <div className="tab-opacity" id="pills-ui-design" role="tabpanel" aria-labelledby="pills-ui-design-tab">
                                <div className="div-block-40">
                                    <div className="row">
                                        <div className="col-lg-6 space_right">
                                            <div className="projects me-0">
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/ui/teatoxlife" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/labelteatox.webp" loading="lazy" alt="Tea Tox" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Tea Tox Life</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Natural Herbal Wellness <br className="d-lg-block d-none" /> for Healthy Living</h2>
                                                        <p className="paragraph-13 color_light change-color">UI design, UX design, Wireframe</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/ui/apple-elevators" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/uiapple.webp" loading="lazy" alt="Apple" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Apple Elevators</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Elevators mobility with <br className="d-lg-block d-none" /> innovative solutions.</h2>
                                                        <p className="paragraph-13 color_light change-color">UI design, UX design, Wireframe</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/ui/healing-hands" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/uihealing.webp" loading="lazy" alt="Healing Hands" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Healing Hands</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Plant-based Medical <br className="d-lg-block d-none" /> innovation</h2>
                                                        <p className="paragraph-13 color_light change-color">UI design, UX design, Wireframe</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 space_left">
                                            <div className="projects margin-top me-0">
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/ui/theone" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/uitheone.webp" loading="lazy" alt="The One" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">The One</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Government blockchain <br className="d-lg-block d-none" /> innovator, shaping <br className="d-lg-block d-none" /> the future.</h2>
                                                        <p className="paragraph-13 color_light change-color">UI design, UX design, Wireframe</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/ui/lycanpay" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/uilycanpay.webp" loading="lazy" alt="Lycan Pay" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Lycan Pay</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Revolutionizing payments <br className="d-lg-block d-none" /> with blockchain technology.</h2>
                                                        <p className="paragraph-13 color_light change-color">UI design, UX design, Wireframe</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === "packaging-design" && (
                            <div className="tab-opacity" id="pills-packaging" role="tabpanel" aria-labelledby="pills-packaging-tab">
                                <div className="div-block-40">
                                    <div className="row">
                                        <div className="col-lg-6 space_right">
                                            <div className="projects me-0">
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/label/aumex" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/labelaumex.webp" loading="lazy" alt="Aumex" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Aumex</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Revitalize Intimate <br className="d-lg-block d-none" /> Wellness with Care</h2>
                                                        <p className="paragraph-13 color_light change-color">Product Packaging, 3D Modelling</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/label/shishtaka" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/labelshishtaka.webp" loading="lazy" alt="Shishtaka" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Shishtaka</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Blending Ayurvedic <br className="d-lg-block d-none" /> wisdom with modern <br className="d-lg-block d-none" /> wellness philosophy.</h2>
                                                        <p className="paragraph-13 color_light change-color">Product Packaging, 3D Modelling</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/label/naturare" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/labelnaturare.webp" loading="lazy" alt="Naturare" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Naturare</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Transforming wellness and <br className="d-lg-block d-none" /> empowering lives naturally.</h2>
                                                        <p className="paragraph-13 color_light change-color">Product Packaging, 3D Modelling</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/label/ras-beauty" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/ras.webp" loading="lazy" alt="Ras Beauty" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Ras Beauty</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Farm-to-Face Luxury Skincare with Ayurvedic Essence</h2>
                                                        <p className="paragraph-13 color_light change-color">Product Packaging, 3D Modelling</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 space_left">
                                            <div className="projects margin-top me-0">
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/label/teatoxlife" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/labelteatox.webp" loading="lazy" alt="Tea Tox" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Tea Tox Life</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Natural Herbal Wellness <br className="d-lg-block d-none" /> for Healthy Living</h2>
                                                        <p className="paragraph-13 color_light change-color">Product Packaging, 3D Modelling</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/label/healing-hands" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/labelhealing.webp" loading="lazy" alt="Healing Hands" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Healing Hands</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Plant-based Medical <br className="d-lg-block d-none" /> innovation</h2>
                                                        <p className="paragraph-13 color_light change-color">Product Packaging, 3D Modelling</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/label/mypro" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/labelmypro.webp" loading="lazy" alt="My Pro" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">My Pro Sport Nutritions</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Fueling fitness, <br className="d-lg-block d-none" /> wellness, and <br className="d-lg-block d-none" /> peak performance</h2>
                                                        <p className="paragraph-13 color_light change-color">Product Packaging, 3D Modelling</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/label/nourysh" className="project-link">
                                                        <img src="/assets/image/thumbnailimage/labelnourysh.webp" loading="lazy" alt="Nourysh" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Nourysh</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Fueling fitness, <br className="d-lg-block d-none" /> wellness, and <br className="d-lg-block d-none" /> peak performance</h2>
                                                        <p className="paragraph-13 color_light change-color">Product Packaging, 3D Modelling</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === "branding" && (
                            <div className="tab-opacity" id="pills-branding" role="tabpanel" aria-labelledby="pills-branding-tab">
                                <div className="div-block-40">
                                    <div className="row">
                                        <div className="col-lg-6 space_right">
                                            <div className="projects me-0">
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/branding/abox" className="project-link" onMouseOver={() => { changeColor('#333', '#fff') }}
                                                        onMouseOut={() => {
                                                            changeColor('#222', '#efebe7')
                                                        }}>
                                                        <img src="/assets/image/abox.webp" loading="lazy" alt="aBox" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Box Agency</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Elevate Your Online <br className="d-lg-block d-none" /> Presence with aBox</h2>
                                                        <p className="paragraph-13 color_light change-color">Digital Presence</p>
                                                    </a>
                                                </div>
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/branding/nourysh" className="project-link" onMouseOver={() => { changeColor('#36386D', '#A6A9E9') }}
                                                        onMouseOut={() => {
                                                            changeColor('#222', '#efebe7')
                                                        }}>
                                                        <img src="/assets/image/nourysh_brand.webp" loading="lazy" alt="Nourysh" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Nourysh</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Science-backed focused <br className="d-lg-block d-none" /> on self-nourishment.</h2>
                                                        <p className="paragraph-13 color_light change-color">Digital Presence</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 space_left">
                                            <div className="projects margin-top me-0">
                                                <div className="anand-sweets">
                                                    <a href="/portfolio/branding/terahash-solutions" className="project-link" onMouseOver={() => { changeColor('#0F0F0F', '#fff') }}
                                                        onMouseOut={() => {
                                                            changeColor('#222', '#efebe7')
                                                        }}>
                                                        <img src="/assets/image/terahash_brand.webp" loading="lazy" alt="Terahash Solutions" className="w-100" />
                                                        <div className="div-block-42">
                                                            <p className="project-name color_light change-color">Terahash Solutions</p>
                                                            <div className="line dark_line" />
                                                        </div>
                                                        <h2 className="project-info color_light change-color">Innovative ASIC mining solutions for sustainable profitability.</h2>
                                                        <p className="paragraph-13 color_light change-color">Digital Presence</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}