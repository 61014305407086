import React from "react"
import Header from "../component/Header"
import LetsTalk from "../component/LetsTalk"
import Footer from "../component/Footer"
import { Helmet } from "react-helmet"
import { useParams } from "react-router"
import blogsData from "../data/blogs"
import { Link } from "react-router-dom"

export default function BlogDetail() {

  const { title } = useParams()
  const blogdetail = blogsData?.find(e => e.urlTitle == title) || null
  const nextBlog = blogsData[blogsData.indexOf(blogdetail) + 1] || null

  return (
    <>
      <Helmet>
        <title>{blogdetail?.title}</title>
        <meta name="title" content={blogdetail?.title} />
        <meta name="description" content={blogdetail?.subTitle} />
        <meta name="keywords" content="Web Design Tips, Web Development Insights, Digital Marketing Strategies, Website Optimization, Shopify, UIUX, Figma, Shopify Plus, Web Development, Web Designing, SEO Best Practices, User Experience (UX) Design, Responsive Web Design, Content Marketing, E-commerce Solutions, Mobile-Friendly Websites, Web Design Trends, Web Development Tools, Online Branding, Social Media Marketing, Web Analytics, CMS Recommendations, UX/UI Design Trends, Blogging Tips, Web Security, aBox Agency Blog" />
        <link rel="canonical" href="https://www.abox.agency/blogs" />
      </Helmet>

      <Header />

      {/* hero section */}
      <div className="header_padding">
        <div className="section_detail blog_section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="mb-1 font_medium">{blogdetail?.category}</p>
                <h1 className="portfolio_heading">{blogdetail?.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Blog Details */}
      <div className="container">
        <hr className="news_line mt-0" />
        <div className="d-flex justify-content-between align-items-center news_padding">
          <div className="insight_btn">
            <p className="blog_name">{blogdetail?.writer || '-'}</p>
          </div>
          <div className="new-date">
            <span>{blogdetail?.time}</span>
          </div>
        </div>
      </div>

      <div className="blog_img">
        <img src={blogdetail?.image} className="w-100" alt={blogdetail?.title} loading="lazy" />
      </div>

      <div className="blog_details">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="blog_title">{blogdetail?.subTitle}</h2>
            </div>
          </div>
          <hr className="news_line" />
          <div className="semi_title">
            <div dangerouslySetInnerHTML={{ __html: blogdetail?.content }} />
          </div>
        </div>
      </div>
      {
        nextBlog &&
        <div className="yogabar_bg blog_bg" style={{ backgroundImage: `url(${nextBlog.image})` }}>
          <div className="container">
            <div className="text-center">
              <Link to={`/blogs/${nextBlog.urlTitle}`} className="next_button font_regular">Next Blog</Link>
            </div>
          </div>
        </div>
      }

      <LetsTalk />

      <Footer />
    </>
  )
}