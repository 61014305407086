import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";
// import useScrollRedirect from "../hooks/useScrollRedirect";

export default function PortfolioThenga() {

    // useScrollRedirect('/portfolio/shistaka')

    return (
        <>
            <Helmet>
                <title>Thenga's Natural Elegance | Portfolio Highlights: Thenga | Box Web Agency</title>
                <meta name="title" content="Thenga's Natural Elegance | Portfolio Highlights: Thenga | Box Web Agency" />
                <meta name="description" content="Embark on a journey through Thenga's enchanting world of coconut shell and wood tableware, beautifully presented by the wizards at Box Web Magic. Uncover Thenga's story and our enchanting web solutions that conjured up their online allure." />
                <meta name="keywords" content="Thenga, Coconut Shell Tableware, Coconut Wood Products, Artistic Design, Web Portfolio, Creative Showcase, Tableware Bowls, Eco-friendly Cutlery, Handcrafted Candle Holders, Serving Spoon, Spatula, Natural Elegance, Sustainable Dining, Artistry Web Studio, Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, Web Development, Portfolio, Success Stories, Online Transformation, WebCraft Studios, Web Development Solutions, Responsive Web Design, E-commerce Integration, User Experience, Enhancement, Mobile-Friendly Websites" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/thenga" />
            </Helmet>

            <Header />

            <div className="page_bg bg_thenga">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name pr_text2_bg text-white">Thenga</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1696503300.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name pr_text2_bg writing_mod text-white">Published in 2023</p>
                                    <h1 className="services_title text-white mb-0">
                                        Thenga Coco
                                        <br /> <span className="title_color text_thenga">
                                            Elevating Online<br />
                                            Eco-Friendly Treasures
                                        </span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white mb-0">Thenga Coco, a startup by a girl from Kerala, offers eco-friendly products crafted from discarded coconut shells, championing sustainability in the home and kitchen industries. The founder, Maria, brimming with ideas but lacking execution know-how, sought guidance from Abox. We assisted Maria in learning how to implement her plans and establish a sustainable online presence. Our services included website design, color choices, typography, and overall development. Today, Thenga Coco is valued at approximately $100 million.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://thengacoco.com/" target="_blank" className="d-flex align-items-center text_thenga">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_thenga services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>For Startup</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_thenga services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Eco-friendly coconut-based products</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_thenga_logo">
                                <img src="/assets/image/portfolios/thenga/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/thenga/thenga2.webp" className="w-100" alt="thenga coco" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Crafting the heart of <br />
                                        a green brand</h2>
                                    <p className="services_pera text-white font_regular mb-0">Our technical wizards worked tirelessly to bring Thenga Coco's vision to life, tailoring every feature to meet their unique needs. They created a seamless shopping experience and a user-friendly interface, showcasing a diverse range of nature-inspired offerings.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/thenga/thenga3.webp"
                                            alt="thenga coco" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/thenga/thenga4.webp"
                                            alt="thenga coco" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/thenga/thenga5.webp"
                                            alt="thenga coco" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/thenga/thenga6.webp"
                                            alt="thenga coco" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/thenga/thenga7.webp"
                                    alt="thenga coco" className="img-fluid w-100" />
                                {/* <img src="/assets/image/portfolios/thenga/thenga7.webp" alt="thenga coco" /> */}
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_thenga_logo">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/thenga/thenga8.webp"
                                        alt="thenga coco" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/thenga/thenga9.webp"
                                        alt="thenga coco" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/thenga/thenga10.webp"
                                        alt="thenga coco" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text-white">Strengthening the essence of sustainability with finesse.</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Thenga Coco offers a delightful journey through the digital realm, blending the wonders of coconut-based products with modern technology. Visit <a href="https://thengacoco.com/" className="text-white portfolio_a" target="_blank">thengacoco.com</a> for a harmonious symphony of eco-friendly elegance.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/thenga/thenga11.webp"
                                        alt="thenga coco" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/thenga/thenga12.webp"
                                        alt="thenga coco" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/thenga/thenga13.webp"
                                        alt="thenga coco" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/thenga/thenga14.webp"
                                        alt="thenga coco" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/thenga/thenga15.webp"
                                    alt="thenga coco" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/thenga/thenga16.webp" className="w-100" alt="thenga coco color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/thenga/thenga17.webp" alt="thenga coco font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text-white perform_pera">Working with aBox (Niqox) has been a delight. They beautifully captured our brand's essence while creating a visually captivating website. Their technical expertise and dedication to our vision resulted in an exceptional online shopping experience for our customers. We highly recommend aBox (Niqox) for their professionalism and ability to exceed expectations.</p>
                                    <h3 class="title_color padd_s text_thenga">Maria Kuriakose</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Thenga Coco</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg shistaka_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/shistaka" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}