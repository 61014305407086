import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";
// import useScrollRedirect from "../hooks/useScrollRedirect";

export default function PortfolioGoGrub() {

    // useScrollRedirect('/portfolio/anandsweets')

    return (
        <>
            <Helmet>
                <title>Go Grub Delights | Snack Sensations WorkFolio | Box Web Agency</title>
                <meta name="title" content="Go Grub Delights | Snack Sensations WorkFolio | Box Web Agency" />
                <meta name="description" content="Delve into the delectable universe of Go Grub, an enticing online platform offering a delightful array of snacks, cookies, and homemade snack foods. Uncover the artistry of our web showcase, meticulously curated by Box Web Agency, showcasing the perfect fusion of flavor and digital innovation." />
                <meta name="keywords" content="Go Grub, Box Web Agency, Shopify, E-commerce, Web Development, Snack Products, Cookies, Homemade Snacks, Web Showcase, Client Work, Web Portfolio, Responsive Design, User-Friendly Website, DigitalCraft Studio, Shopify Store, Website Development, Custom Web Design, Online Store Development, Responsive Web Design, User Experience (UX), Web Development Services, eCommerce Solutions, DigitalCraft Studio, Shopify Experts, Website Design, Web Design Trends, Mobile-Friendly Design, Website Maintenance, SEO Optimization, Conversion Rate Optimization, Web Development Portfolio, Online Presence, Snack Sensations, Client Success Stories, Creative Showcase, Flavorful Moments, Culinary Creations, eCommerce Solutions, Web Design, Impactful Design" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/gogrub" />
            </Helmet>

            <Header />

            <div className="page_bg bg_gogrub">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">Go Grub</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702965088.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2023</p>
                                    <h1 className="services_title oxide_text mb-0">
                                        Savor the Flavor: Elevating Your Snack Game <br /> with a bite-sized Bliss
                                        {/* <span className="oxide_text">
                                            Adventure Haven: <br />
                                            Gear Up for Fun!
                                        </span> */}
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">Using basic pantry ingredients, GoGrub creates healthy goods. Although they already have a website, it is unworthy of the merchandise. Since the existing website was badly designed and did not fulfill the needs of the company or its products, aBox took the time to completely redesign and develop a Shopify website that perfectly complements both the brand and its offerings.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.gogrub.in/" target="_blank" className="d-flex align-items-center text_shiska">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>3D Render</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>For Startup</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Health Food and Beverage</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center gogrub_logo_bg">
                                <img src="/assets/image/portfolios/go_grub/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/go_grub/gogrub1.webp" className="w-100" alt="Go Grub" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Crafting the perfect foundation for a delightful brand</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        Our tech wizards went all-in to ensure Go Grub's vision popped with precision, tailoring every feature to satisfy their unique cravings. We've baked up a seamless online shopping experience with an easy-peasy interface, flaunting our diverse range of tempting snack treasures.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/go_grub/gogrub2.webp"
                                            alt="Go Grub" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/go_grub/gogrub3.webp"
                                            alt="Go Grub" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/go_grub/gogrub4.webp"
                                            alt="Go Grub" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/go_grub/gogrub5.webp"
                                            alt="Go Grub" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/go_grub/gogrub6.webp"
                                    alt="Go Grub" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect gogrub_product_bg">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/go_grub/gogrub7.webp"
                                        alt="Go Grub" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/go_grub/gogrub8.webp"
                                        alt="Go Grub" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/go_grub/gogrub9.webp"
                                        alt="Go Grub" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Enhancing the essence of the brand with finesse</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">
                                        Go Grub promises a snack odyssey, weaving together the timeless and the trendy in a symphony of flavors. Cruise over to <a href="https://www.gogrub.in/" target="_blank" className="official_link">www.gogrub.in</a> for a symphony of taste and indulgence that'll make your taste buds dance.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/go_grub/gogrub10.webp"
                                        alt="Go Grub" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/go_grub/gogrub11.webp"
                                        alt="Go Grub" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/go_grub/gogrub12.webp"
                                        alt="Go Grub" className="img-fluid w-100" />
                                </div>
                            </div>
                            {/* <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/go_grub/gogrub13.webp"
                                    alt="Go Grub" className="img-fluid w-100" />
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title text-white">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/go_grub/gogrub13.webp" className="w-100" alt="Go Grub color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/go_grub/gogrub14.webp" alt="Go Grub font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text_light perform_pera">aBox rocked our world! They whipped up our website with jaw-dropping design and flawless functionality, exceeding our expectations. The team's service is exceptional—warm, skilled, and always ready to turn our snack dreams into reality. Go Grub is the real deal—highly recommend it for a snack-tastic journey!</p>
                                    <h3 class="title_color padd_s text_shiska">Priyanka</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of Go Grub</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg ekkibekki_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/ekkibekki" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}