import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";
import PortfolioHorizontalSlider from "../../component/PortfolioHorizontalSlider";

export default function LabelNourysh() {

    return (
        <>
            <Helmet>
                <title>Nourysh Yourself | 3D Rendering & Modeling | aBox Agency</title>
                <meta name="title" content="Nourysh Yourself | 3D Rendering & Modeling | aBox Agency" />
                <meta name="description" content="Explore how aBox Agency harnessed the power of 3D visuals and engaging infographics to craft a captivating brand identity for Nourysh. We illuminate their commitment to science-backed wellness and ethical sourcing, inspiring healthier lives." />
                <meta name="keywords" content="aBox Agency, Nourysh, 3D visualization, branding, wellness brand design, health and wellness products, science-based formulas, ethically sourced ingredients, e-commerce website design, product packaging design, label design, product rendering, 3D product modeling, health and wellness marketing, digital marketing agency, SEO for health brands, e-commerce conversion optimization, brand storytelling, target audience engagement, data-driven design, creative agency, results-oriented marketing, infographics" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/nourysh" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                <h1 className="services_title mb-0"><span>Nourysh</span> <br /> <span className="text_black">3D Visuals for <br /> a Healthier You</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/nourysh/nourysh1.webp"
                        alt="nourysh" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">aBox Agency empowers Nourysh's wellness vision through compelling 3D visuals and informative infographics. We crafted a cohesive brand identity with impactful 3D renders, intricate modeling, sleek label designs, and engaging infographics. Our visuals capture the essence of Nourysh's science-backed formulations and ethical sourcing, empowering their customers to achieve their health goals and embrace a life of wellness.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>3D Render</li>
                                            <li>3D Modeling</li>
                                            <li>Label Design</li>
                                            <li>Product Visualization</li>
                                            <li>Infographics</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Health and Wellness Supplements</li>
                                            <li>E-Commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* view section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/nourysh/nourysh2.webp" className="w-100" alt="nourysh" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/nourysh/nourysh3.webp" className="w-100" alt="nourysh" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/nourysh/nourysh4.webp" className="w-100" alt="nourysh" />
                        </div>

                        <div className="row mt_30 mb-5 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/nourysh/nourysh5.webp" className="w-100" alt="nourysh" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/nourysh/nourysh6.webp" className="w-100" alt="nourysh" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <PortfolioHorizontalSlider /> */}

            <LetsTalk />

            <Footer />
        </>
    );
}