import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
// import useScrollRedirect from "../hooks/useScrollRedirect";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioNourysh() {

    // useScrollRedirect('/portfolio/dr-vaidya')

    return (
        <>
            <Helmet>
                <title>Nourysh Work Spotlight | Empowering Brands: Nourysh | Box Web Agency</title>
                <meta name="title" content="Nourysh Work Spotlight | Empowering Brands: Nourysh | Box Web Agency" />
                <meta name="description" content="Explore the spotlight on Nourysh's work and how Box Web Agency empowers brands. Delve into the collaborative journey that empowered Nourysh's online presence and brand identity." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Nourysh Work Spotlight, Empowering Brands, Nourysh Brand Success, Box Web Agency, Online Empowerment, Web Branding, Client Success Story, Brand Identity, Web Development, Nourysh Online, Web Agency Collaboration, Creative Branding, Client Testimonial, Business Growth, Brand Empowerment, Creative Web Solutions, Web Design Showcase, Success Through Collaboration, Web Impact, Box Client Stories, Nourysh's Journey, Brand Transformation, Web Agency Success, Empowering Digital Presence,Nourysh's Online Journey" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/nourysh" />
            </Helmet>

            <Header />

            <div className="page_bg nourysh_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name Nourysh_subtext">NOURYSH</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1690894276.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name Nourysh_subtext writing_mod">Published in 2023</p>
                                    <h1 className="services_title text-white mb-0">Nourysh<br /> <span className="nourysh_title">Nourishing the World with Scientifically Backed Nutrition</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white mb-0">Nourysh, a health brand, asked us to make their website. They sell good products for health, like vitamins and supplements. They wanted a website that showed their products nicely. We made it easy for people to use. Now, anyone can go online and find what they need to be healthy. We're happy to help Nourysh share good things for everyone's health</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://nouryshyourself.com/" target="_blank" className="d-flex align-items-center nourysh_title">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="nourysh_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>3D Render</li>
                                                <li>Web Design</li>
                                                <li>Development</li>
                                                <li>For Startup</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="nourysh_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Essential health nutrition</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner nourysh_banner d-flex justify-content-center align-items-center">
                                <img src="/assets/image/product/nourysh/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/product/nourysh/product.webp" className="w-100" alt="nourysh" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Crafting a Digital Hub for Nutritional Excellence</h2>
                                    <p className="services_pera text-white font_regular mb-0">Our team's mission was to design and develop a website that resonates with health-conscious individuals and professionals seeking scientifically proven nutritional solutions. Through careful planning and creative execution, we built a digital space that seamlessly combines user-friendly functionality with an attractive user interface.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/nourysh/nourysh1.webp"
                                            alt="nourysh" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/nourysh/nourysh2.webp"
                                            alt="nourysh" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/nourysh/nourysh3.webp"
                                            alt="nourysh" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/nourysh/nourysh4.webp"
                                            alt="nourysh" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/product/nourysh/nourysh_digital.webp" alt="nourysh" className="w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_whites">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/nourysh/nourysh5.webp"
                                        alt="nourysh" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/nourysh/nourysh6.webp"
                                        alt="nourysh" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/nourysh/nourysh7.webp"
                                        alt="nourysh" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Building a Strong Global Brand</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">Nourysh aimed to attract Western clients by implementing localization strategies and utilizing market research and branding techniques. They established a compelling digital identity, positioning themselves as a worldwide leader in science-backed nutrition. Our team optimized website performance across devices and browsers, ensuring a smooth user experience. We implemented easy navigation, streamlined content presentation, and efficient e-commerce features, allowing users to explore Nourysh's products and make informed decisions effortlessly.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/nourysh/nourysh8.webp"
                                        alt="nourysh" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/nourysh/nourysh9.webp"
                                        alt="nourysh" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/nourysh/nourysh10.webp"
                                        alt="nourysh" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/nourysh/nourysh11.webp"
                                        alt="nourysh" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/nourysh/nourysh12.webp"
                                    alt="nourysh" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title nourysh_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/product/nourysh/nourysh_palette.webp" className="w-100" alt="nourysh color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/product/nourysh/reckless.webp" alt="nourysh font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text-white perform_pera">Working with aBox (Niqox) has been an exceptional experience. They truly understood our vision and transformed it into a stunning digital platform that captures the essence of our brand. Their expertise in digital presence, website development, and branding has positioned Nourysh as a global player in scientifically backed nutrition. We are grateful for their professionalism, dedication, and ability to exceed expectations. We highly recommend aBox (Niqox) to any company seeking to establish a strong online presence and attract international clientele.”</p>
                                    <h3 class="nourysh_title padd_s">Shiven</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Nourysh</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg dr_vaidya_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/dr-vaidya" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}