import React, { useEffect, useRef } from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import PortfolioHorizontalSlider from "../component/PortfolioHorizontalSlider";

gsap.registerPlugin(ScrollTrigger);

export default function ServiceWebDevelopment() {

    const section1Ref = useRef(null);
    const colLeftRef = useRef(null);

    useEffect(() => {
        if (window.innerWidth > 1024) {
            function raf(time) {
                ScrollTrigger.update();
                requestAnimationFrame(raf);
            }

            requestAnimationFrame(raf);

            const section1 = section1Ref.current;
            const colLeft = colLeftRef.current;
            const timeline = gsap.timeline({ paused: true });

            timeline.fromTo(
                colLeft,
                { y: 0 },
                { y: '170vh', duration: 1, ease: 'none' },
                0
            );

            ScrollTrigger.create({
                animation: timeline,
                trigger: section1,
                start: 'top top',
                end: 'bottom center',
                scrub: true,
            });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Web Development For Your Vision | Top-notch Web Development Solutions | Box Web Agency</title>
                <meta name="title" content="Web Development For Your Vision | Top-notch Web Development Solutions | Box Web Agency" />
                <meta name="description" content="Realize your vision with top-notch web development solutions from Box Web Agency. Our expert team crafts websites that align perfectly with your goals and aspirations." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Web Development, Web Development Solutions, Box Web Agency, Expert Web Development, Custom Web Solutions, Website Design, Professional Web Development, Creative Web Solutions, Web Design Excellence, Web Development Services, Website Crafting, Web Development Experts, Responsive Design, User-Centric Websites, Website Implementation, Web Development Process, Innovative Web Solutions, Tailored Web Development, Web Design and Coding, Web Agency Services, Customized Website Development, Build Your Website, Expert Web Developers, Web Projects Web Excellence" />
                <link rel="canonical" href="https://www.abox.agency/services/webdevelopment" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="header_padding">
                <div className="section_detail">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-7 sec_padding shopi_padding">
                                <h1 className="service_heading">Unmatched Web Development Ministration</h1>
                                <p className="seo_p">At aBox Agency, we craft digital wonders! Experience the benefits of our web development expertise: stunning designs, seamless user experiences, enhanced online presence, and skyrocketing business growth. Let us turn your visions into reality and take your brand to new heights!</p>
                            </div>
                            <div className="col-lg-5 pe-0 text-end ps-lg-3 ps-0">
                                <div>
                                    <img src="/assets/image/service/development.webp" alt="development" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* seo detail section */}
            <div className="section_detail seo_section">
                <div className="container">
                    <div className="row padding_bottom border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Experience the Beauty of a Well-Constructed Website</h2>
                        </div>
                        <div className="col-xl-6">
                            <p className="seo_content">Your website is the ultimate online ambassador, opening the bridge between your brand and a potential customer. It should be modern, aesthetically pleasing, and technically sound to offer the user a seamless and immersive journey.</p>
                            <p className="seo_content mb-0">We're here to build that foundation for you, integrating calculated animations and interactive elements to captivate visitors without forfeiting optimal functionality.</p>
                        </div>
                    </div>
                    <div className="row feature_padding border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">The best features We provide</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex flex-wrap">
                                <p className="feature_p">Agile Approach</p>
                                <p className="feature_p">Clean Code</p>
                                <p className="feature_p">Version Control</p>
                                <p className="feature_p">Testing Automation</p>
                                <p className="feature_p">Scalable Architecture</p>
                                <p className="feature_p">Security Measures</p>
                                <p className="feature_p">Continuous Integration</p>
                                <p className="feature_p">Cross-Browser Compatibility</p>
                            </div>
                        </div>
                    </div>
                    <div className="row feature_padding pb-0">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Why aBox?</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Empowering Functionality</h3>
                                    <p className="seo_content mb-0 font_light">We specialize in optimizing your website’s features, integrations, and workflows to streamline operations, boost productivity, and provide an exceptional user experience. From custom app development to seamless integrations, we'll help your business reach its full potential.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Lightning-Fast Speed</h3>
                                    <p className="seo_content mb-0 font_light">Our optimization techniques and performance enhancements ensure swift loading times, providing a seamless browsing experience for your customers. From code optimization to image compression, we'll accelerate your Website's speed, maximizing user satisfaction and driving higher conversion rates. </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Cross-Platform Responsiveness</h3>
                                    <p className="seo_content mb-0 font_light">We ensure your website looks and performs flawlessly on various devices, including desktops, tablets, and smartphones. With responsive design techniques and thorough testing, we guarantee that your store adapts to different screen sizes and resolutions, engaging customers wherever they are.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">E-Commerce Solutions</h3>
                                    <p className="seo_content mb-0 font_light">In the 22nd century, online shopping has become a way of life. Our e-commerce solutions are designed to help you tap into this ever-growing market. We integrate secure payment gateways, intuitive product catalogs, and seamless checkout processes, ensuring a smooth and secure online shopping experience for your customers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* image section */}
            <div className="section_detail image_section detail_section development_section" ref={section1Ref}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" ref={colLeftRef}>
                            <h2 className="service_h2 title_margin">Expert website development <br className="d-md-block d-none" />
                                that drives results.</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/1</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Custom Web Development</h3>
                                    <p className="services_p">We understand that every business is unique, and your website should reflect your brand's individuality. Our web development experts will work closely with you to analyze your requirements and create custom web solutions that align with your vision.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/2</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Responsive Web Design</h3>
                                    <p className="services_p">With the proliferation of devices and screen sizes, your website needs to adapt seamlessly to every user's experience. Our team of skilled designers will create visually stunning and responsive web designs that engage your audience across all platforms, ensuring a consistent and captivating user experience.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/3</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Progressive Web Apps (PWAs):</h3>
                                    <p className="services_p">As technology continues to evolve, users demand faster, more immersive experiences. Our expertise in building Progressive Web Apps ensures your website combines the best of web and mobile applications. With offline capabilities, push notifications, and native app-like interactions, PWAs developed by us provide an enhanced user experience while being accessible across various devices.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/4</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Powerful CMS Solutions</h3>
                                    <p className="services_p">Our CMS (content management system) services empower you to effortlessly manage and update your website's content, allowing you to focus on what matters most: your business. From adding new pages to editing existing content, our intuitive CMS platforms provide you with complete control and flexibility. Experience the convenience of efficient content management with our CMS solutions tailored to your specific needs.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/5</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">API Integration and Backend Development</h3>
                                    <p className="services_p">We excel at integrating diverse APIs to enhance your website's functionality. Whether it's integrating payment gateways, social media platforms, or third-party services, our developers have the expertise to seamlessly connect your website with external systems, ensuring smooth data flow and enhanced user interactions.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/6</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Website Optimization and Performance</h3>
                                    <p className="services_p">In the fast-paced digital landscape, speed and performance are paramount. Our web optimization team employs advanced techniques to fine-tune your website, delivering lightning-fast loading times, optimal resource utilization, and exceptional performance. By optimizing code, compressing assets, and implementing caching mechanisms, we guarantee an exceptional user experience.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/7</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Cybersecurity and Data Protection</h3>
                                    <p className="services_p">As technology advances, so do potential threats. Our security experts take a proactive approach to safeguarding your website and customer data. From implementing robust encryption protocols to performing regular vulnerability assessments, we ensure your website remains secure, protecting your business and your customers' privacy.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin mb-0">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/8</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Strategy-Driven Solutions</h3>
                                    <p className="services_p">Our approach is rooted in thorough analysis and planning to ensure your website aligns seamlessly with your business objectives. From crafting user-centric designs to implementing modern technologies, we create websites that not only captivate your audience but also drive meaningful results.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PortfolioHorizontalSlider />

            <LetsTalk />

            <Footer />
        </>
    );
}