import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";
// import useScrollRedirect from "../hooks/useScrollRedirect";

export default function PortfolioRadheySweet() {

    // useScrollRedirect('/portfolio/healthoxide')

    return (
        <>
            <Helmet>
                <title>Classic Radhey Sweets Portfolio | Indian Traditional Sweets | Box Web Agency</title>
                <meta name="title" content="Classic Radhey Sweets Portfolio | Indian Traditional Sweets | Box Web Agency" />
                <meta name="description" content="Explore our showcase of Classic Radhey Sweets, the home of authentic Indian traditional sweets. See how Box Web Agency helped highlight the rich heritage and sweet delights of this renowned confectioner through our web design and development expertise." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, UI/UX, Classic Radhey Sweets, Indian Traditional Sweets, Traditional Confectionery, Box Web Agency, Web Design, Web Development, E-commerce, Portfolio Showcase, Client Success Stories, Collaborations, Valued Partnerships, Sweet Delights, Traditional Indian Desserts, Sweet Treats, Online Portfolio, Creative Solutions, Digital Marketing, Responsive Design, SEO Optimization, Authentic Sweets, Rich Heritage, Dessert Showroom, Confectionery Excellence, Sweet Traditions" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/radheysweet" />
            </Helmet>

            <Header />

            <div className="page_bg bg_radhey_sweets">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">radheysweets</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1697525437.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2021</p>
                                    <h1 className="services_title text_yellow mb-0">
                                        Satisfying Your Sweet <br /> Cravings with Authentic <br /> Indian Delights
                                        {/* <span className="text_yellow">
                                            Adventure Haven: <br />
                                            Gear Up for Fun!
                                        </span> */}
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">We decided to highlight the advantages of creating an online presence because Radhe Sweets, a well-known sweet shop located in Lucknow, did not have one. When we saw the potential, we built a Shopify website, especially to enhance their brand's online presence. These days, this sweets brand is not just a hidden gem in the area; it also offers its mouthwatering treats to people all over India.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://classicradheysweets.in/" target="_blank" className="d-flex align-items-center text_shiska">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>sweets and confectionery.</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_yellow">
                                <img src="/assets/image/portfolios/radhey_sweets/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets2.webp" className="w-100" alt="radhey sweets" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Creating a Digital Oasis of Sweet Temptations</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        We embarked on a journey to create a captivating online presence that mirrors the essence of Classic Radhey Sweets. Our expert designers carefully weaved together the vibrant colors, intricate designs, and mouthwatering imagery of these sweet delicacies. The result is an enticing online space that beckons you to explore the treasure trove of Indian sweets and namkeen.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets3.webp"
                                            alt="radhey sweets" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets4.webp"
                                            alt="radhey sweets" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets5.webp"
                                            alt="radhey sweets" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets6.webp"
                                            alt="radhey sweets" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets7.webp"
                                    alt="radhey sweets" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_yellow">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets8.webp"
                                        alt="radhey sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets9.webp"
                                        alt="radhey sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets10.webp"
                                        alt="radhey sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Crafting a Website that Satisfies</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">
                                        Our tech-savvy team dedicated themselves to building a website that resonates with Classic Radhey Sweets' vision. We've tailored every feature to cater to their unique requirements, offering a seamless online shopping experience through an intuitive and user-friendly interface. Classic Radhey Sweets’ website, <a href="https://classicradheysweets.in/" target="_blank" className="text_light services_link">https://classicradheysweets.in/</a> takes you on a delightful journey through India's sweet traditions.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets11.webp"
                                        alt="radhey sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets12.webp"
                                        alt="radhey sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets13.webp"
                                        alt="radhey sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets14.webp"
                                        alt="radhey sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets15.webp"
                                    alt="radhey sweets" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets16.webp" className="w-100" alt="radhey sweets color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/radhey_sweets/radhey_sweets17.webp" alt="radhey sweets font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text_light perform_pera">Working with Abox Agency was a sweet experience! They crafted our website for Classic Radhey Sweets with finesse, showcasing our traditional Indian sweets beautifully. The site captures our essence and heritage. Thank you for your incredible work in bringing our sweet treats to the digital world.</p>
                                    <h3 class="title_color padd_s text_shiska">Mr. Aditya Gupta</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">CEO of Radhey Sweets</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg healthoxide_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/healthoxide" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}