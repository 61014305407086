export const backgroundColors = {
    "/portfolio/anandsweets": "#1B1830",
    "/portfolio/yogabar": "#0C052B",
    "/portfolio/nourysh": "#01191F",
    "/portfolio/dr-vaidya": "#083544",
    "/portfolio/givernutritions": "#2E170A",
    "/portfolio/bayla": "#2C3001",
    "/portfolio/chhappanbhog": "#171C26",
    "/portfolio/prakruti": "#022816",
    "/portfolio/hyfun": "#102602",
    "/portfolio/hamdard": "#2E0304",
    "/portfolio/thenga": "#231302",
    "/portfolio/shistaka": "#241B03",
    "/portfolio/sportsaber": "#260C04",
    "/portfolio/dlfilms": "#171717",
    "/portfolio/ubar": "#401603",
    "/portfolio/freshleaf": "#212721",
    "/portfolio/radheysweet": "#04272E",
    "/portfolio/healthoxide": "#052F46",
    "/portfolio/maa-and-baby": "#014263",
    "/portfolio/gogrub": "#031929",
    "/portfolio/ekkibekki": "#07313C",
    "/portfolio/mypro": "#161616",
    "/portfolio/teatoxlife": "#0D2318",
    "/portfolio/aumex": "#050121",
    "/portfolio/greenfay": "#2E0B01",
    "/portfolio/paperclip-co": "#040328",
    "/portfolio/naturare": "#011A0D",
    "/portfolio/fast-n-fry": "#130F0F",
    "/portfolio/neoroots": "#14332C",
    "/portfolio/within-beauty": "#412382",
    "/portfolio/uppercase": "#063541",
    "/portfolio/om-sweets": "#C01F25",
    "/portfolio/healing-hands": "#032C2F",
    "/portfolio/yo-gathiya": "#7C0A21",
    "/portfolio/codifi": "#311367",
    "/portfolio/terahash-solutions": "#0F0F0F",
    "/portfolio/kutum-ayurveda": "#975C24",
    "/portfolio/arospice": "#204F34",
    "/portfolio/fone-network": "#07083A",
    "/portfolio/repeat-gud": "#131313",
    "/portfolio/nurdle": "#1E2B3C",
    "/portfolio/aurolab": "#005268",
    "/portfolio/technosport": "#1B1B1B",
    "/portfolio/westerville": "#202C39",
    "/portfolio/myoa-cookies": "#29120F",
    "/portfolio/sparkfusion": "#151213",
    "/portfolio/bontrue-furniture": "#280E0A",
    "/portfolio/bhagavad-gita": "#451018",
    "/portfolio": "#222",
    "/portfolio/branding/abox": "#222222",
    "/portfolio/branding/terahash-solutions": "#0F0F0F",
    "/portfolio/branding/nourysh": "#36386D",
}

export const fontColors = {
    "/portfolio/anandsweets": "#C9C5E6",
    "/portfolio/yogabar": "#E3DCFF",
    "/portfolio/nourysh": "#FFFFFF",
    "/portfolio/dr-vaidya": "#BDF0FF",
    "/portfolio/givernutritions": "#FFFFFF",
    "/portfolio/bayla": "#FFFFFF",
    "/portfolio/chhappanbhog": "#BED2FF",
    "/portfolio/prakruti": "#90E3BD",
    "/portfolio/hyfun": "#DEFBCC",
    "/portfolio/hamdard": "#FBE5E6",
    "/portfolio/thenga": "#EDEAE0",
    "/portfolio/shistaka": "#F2E2BA",
    "/portfolio/sportsaber": "#efebe7",
    "/portfolio/dlfilms": "#FFFFFF",
    "/portfolio/ubar": "#FFFFFF",
    "/portfolio/freshleaf": "#FFFFFF",
    "/portfolio/radheysweet": "#FFFFFF",
    "/portfolio/healthoxide": "#FFFFFF",
    "/portfolio/maa-and-baby": "#E8FAFF",
    "/portfolio/gogrub": "#E8FAFF",
    "/portfolio/ekkibekki": "#E8FAFF",
    "/portfolio/mypro": "#E8FAFF",
    "/portfolio/teatoxlife": "#E8FAFF",
    "/portfolio/aumex": "#E8FAFF",
    "/portfolio/greenfay": "#E8FAFF",
    "/portfolio/paperclip-co": "#E8FAFF",
    "/portfolio/naturare": "#E8FAFF",
    "/portfolio/fast-n-fry": "#E8FAFF",
    "/portfolio/neoroots": "#E8FAFF",
    "/portfolio/within-beauty": "#E3DCFF",
    "/portfolio/uppercase": "#D1F2F8",
    "/portfolio/om-sweets": "#FBF4EC",
    "/portfolio/healing-hands": "#FFFFFF",
    "/portfolio/yo-gathiya": "#FFFFFF",
    "/portfolio/codifi": "#FFFFFF",
    "/portfolio/terahash-solutions": "#FFFFFF",
    "/portfolio/kutum-ayurveda": "#FFFFFF",
    "/portfolio/arospice": "#FFFFFF",
    "/portfolio/fone-network": "#FFFFFF",
    "/portfolio/repeat-gud": "#FFFFFF",
    "/portfolio/nurdle": "#FFFFFF",
    "/portfolio/aurolab": "#FFFFFF",
    "/portfolio/technosport": "#FFFFFF",
    "/portfolio/westerville": "#FFFFFF",
    "/portfolio/myoa-cookies": "#FFFFFF",
    "/portfolio/sparkfusion": "#FFFFFF",
    "/portfolio/bontrue-furniture": "#FFFFFF",
    "/portfolio/bhagavad-gita": "#FFFFFF",
    "/portfolio": "#EFEBE7",
    "/portfolio/branding/abox": "#ffffff",
    "/portfolio/branding/terahash-solutions": "#C9C5E6",
    "/portfolio/branding/nourysh": "#C9C5E6",
    "/conversion-rate-optimizations": "#004737",
}

export const buttonColors = {
    "/portfolio/anandsweets": "#9C8EFF",
    "/portfolio/yogabar": "#A1A7FF",
    "/portfolio/nourysh": "#E1E4FF",
    "/portfolio/dr-vaidya": "#BDF0FF",
    "/portfolio/givernutritions": "#FFD4AC",
    "/portfolio/bayla": "#EFFFBE",
    "/portfolio/chhappanbhog": "#BED2FF",
    "/portfolio/prakruti": "#90E3BD",
    "/portfolio/hyfun": "#DEFBCC",
    "/portfolio/hamdard": "#FBE5E6",
    "/portfolio/thenga": "#EDEAE0",
    "/portfolio/shistaka": "#F2E2BA",
    "/portfolio/sportsaber": "#EF6940",
    "/portfolio/dlfilms": "#F5F1EB",
    "/portfolio/ubar": "#F5D8CB",
    "/portfolio/freshleaf": "#F5FAE2",
    "/portfolio/radheysweet": "#FFFBD2",
    "/portfolio/healthoxide": "#E8FAFF",
    "/portfolio/maa-and-baby": "#E8FAFF",
    "/portfolio/gogrub": "#E8FAFF",
    "/portfolio/ekkibekki": "#E8FAFF",
    "/portfolio/mypro": "#E8FAFF",
    "/portfolio/teatoxlife": "#E8FAFF",
    "/portfolio/aumex": "#E8FAFF",
    "/portfolio/greenfay": "#E8FAFF",
    "/portfolio/paperclip-co": "#E8FAFF",
    "/portfolio/naturare": "#E8FAFF",
    "/portfolio/fast-n-fry": "#E8FAFF",
    "/portfolio/neoroots": "#E8FAFF",
    "/portfolio/within-beauty": "#A976FB",
    "/portfolio/uppercase": "#8AC5CF",
    "/portfolio/om-sweets": "#E89580",
    "/portfolio/healing-hands": "#AAF9FF",
    "/portfolio/yo-gathiya": "#932138",
    "/portfolio/codifi": "#A38DC9",
    "/portfolio/terahash-solutions": "#2E2E2E",
    "/portfolio/kutum-ayurveda": "#C29468",
    "/portfolio/arospice": "#69917A",
    "/portfolio/fone-network": "#2B2DAE",
    "/portfolio/repeat-gud": "#373737",
    "/portfolio/nurdle": "#808B9A",
    "/portfolio/aurolab": "#4C94A7",
    "/portfolio/technosport": "#595959",
    "/portfolio/westerville": "#657789",
    "/portfolio/myoa-cookies": "#8C705F",
    "/portfolio/sparkfusion": "#7B7879",
    "/portfolio/bontrue-furniture": "#5B413D",
    "/portfolio/bhagavad-gita": "#641622",

    "/services/shopify": "#222222",
    "/services/uiux": "#222222",
    "/services/visualidentity": "#222222",
    "/services/webdesign": "#222222",
    "/services/webdevelopment": "#222222",
    "/services/seo": "#222222",
    "/services/forstartup": "#222222",
    "/services/abtesting": "#222222",
    "/services/3d-render": "#222222",
    "/portfolio": "#EFEBE7",
    "/portfolio/branding/abox": "#ffffff",
    "/portfolio/branding/terahash-solutions": "#2E2E2E",
    "/portfolio/branding/nourysh": "#898CE2",
    "/conversion-rate-optimization": "#004737",
}

export const buttonFontColors = {
    "/portfolio/anandsweets": "#FFFFFF",
    "/portfolio/yogabar": "#FFFFFF",
    "/portfolio/nourysh": "#222",
    "/portfolio/dr-vaidya": "#083544",
    "/portfolio/givernutritions": "#112E3B",
    "/portfolio/bayla": "#2C3001",
    "/portfolio/chhappanbhog": "#161920",
    "/portfolio/prakruti": "#1B1830",
    "/portfolio/hyfun": "#1B1830",
    "/portfolio/hamdard": "#1B1830",
    "/portfolio/thenga": "#1B1830",
    "/portfolio/shistaka": "#1B1830",
    "/portfolio/dlfilms": "#222222",
    "/portfolio/ubar": "#401603",
    "/portfolio/freshleaf": "#212721",
    "/portfolio/radheysweet": "#04272E",
    "/portfolio/healthoxide": "#052F46",
    "/portfolio/maa-and-baby": "#222222",
    "/portfolio/gogrub": "#222222",
    "/portfolio/ekkibekki": "#222222",
    "/portfolio/mypro": "#222222",
    "/portfolio/teatoxlife": "#222222",
    "/portfolio/aumex": "#222222",
    "/portfolio/greenfay": "#222222",
    "/portfolio/paperclip-co": "#222222",
    "/portfolio/naturare": "#222222",
    "/portfolio/fast-n-fry": "#222222",
    "/portfolio/neoroots": "#222222",
    "/portfolio/within-beauty": "#FFFFFF",
    "/portfolio/uppercase": "#FFFFFF",
    "/portfolio/om-sweets": "#FFFFFF",
    "/portfolio/healing-hands": "#032C2F",
    "/portfolio/yo-gathiya": "#FFFFFF",
    "/portfolio/codifi": "#FFFFFF",
    "/portfolio/terahash-solutions": "#FFFFFF",
    "/portfolio/kutum-ayurveda": "#FFFFFF",
    "/portfolio/arospice": "#FFFFFF",
    "/portfolio/fone-network": "#FFFFFF",
    "/portfolio/repeat-gud": "#FFFFFF",
    "/portfolio/nurdle": "#FFFFFF",
    "/portfolio/aurolab": "#FFFFFF",
    "/portfolio/technosport": "#FFFFFF",
    "/portfolio/westerville": "#FFFFFF",
    "/portfolio/myoa-cookies": "#FFFFFF",
    "/portfolio/sparkfusion": "#FFFFFF",
    "/portfolio/bontrue-furniture": "#FFFFFF",
    "/portfolio/bhagavad-gita": "#FFFFFF",
    "/portfolio": "#222222",
    "/portfolio/branding/abox": "#222222",
    "/portfolio/branding/terahash-solutions": "#FFFFFF",
    "/portfolio/branding/nourysh": "#FFFFFF",
}

export const svgColors = {
    "/portfolio/anandsweets": "#9C8EFF",
    "/portfolio/yogabar": "#A1A7FF",
    "/portfolio/nourysh": "#E1E4FF",
    "/portfolio/dr-vaidya": "#BDF0FF",
    "/portfolio/givernutritions": "#FFD4AC",
    "/portfolio/bayla": "#EFFFBE",
    "/portfolio/chhappanbhog": "#BED2FF",
    "/portfolio/prakruti": "#90e3bd",
    "/portfolio/hyfun": "#DEFBCC",
    "/portfolio/hamdard": "#FBE5E6",
    "/portfolio/thenga": "#EDEAE0",
    "/portfolio/shistaka": "#F2E2BA",
    "/portfolio/sportsaber": "#EF6940",
    "/portfolio/dlfilms": "#F5F1EB",
    "/portfolio/ubar": "#F5D8CB",
    "/portfolio/freshleaf": "#F5FAE2",
    "/portfolio/radheysweet": "#FFFBD2",
    "/portfolio/healthoxide": "#E8FAFF",
    "/portfolio/maa-and-baby": "#E8FAFF",
    "/portfolio/gogrub": "#E8FAFF",
    "/portfolio/ekkibekki": "#E8FAFF",
    "/portfolio/mypro": "#E8FAFF",
    "/portfolio/teatoxlife": "#E8FAFF",
    "/portfolio/aumex": "#E8FAFF",
    "/portfolio/greenfay": "#E8FAFF",
    "/portfolio/paperclip-co": "#E8FAFF",
    "/portfolio/naturare": "#E8FAFF",
    "/portfolio/fast-n-fry": "#E8FAFF",
    "/portfolio/neoroots": "#E8FAFF",
    "/portfolio/within-beauty": "#A976FB",
    "/portfolio/uppercase": "#8AC5CF",
    "/portfolio/om-sweets": "#E89580",
    "/portfolio/healing-hands": "#AAF9FF",
    "/portfolio/yo-gathiya": "#F2B40D",
    "/portfolio/codifi": "#B394EA",
    "/portfolio/terahash-solutions": "#888888",
    "/portfolio/kutum-ayurveda": "#F2D6BB",
    "/portfolio/arospice": "#D4DFD9",
    "/portfolio/fone-network": "#5F61D7",
    "/portfolio/repeat-gud": "#C9C9C9",
    "/portfolio/nurdle": "#C9C9C9",
    "/portfolio/aurolab": "#B2D1D9",
    "/portfolio/technosport": "#B7B7B7",
    "/portfolio/westerville": "#B7B7B7",
    "/portfolio/myoa-cookies": "#F5DFBB",
    "/portfolio/sparkfusion": "#DFFF60",
    "/portfolio/bontrue-furniture": "#F3E5C5",
    "/portfolio/bhagavad-gita": "#C3C2C7",
    "/portfolio": "#EFEBE7",
    "/portfolio/branding/abox": "#ffffff",
    "/portfolio/branding/terahash-solutions": "#ffffff",
    "/portfolio/branding/nourysh": "#A6A9E9",
    "/conversion-rate-optimization": "#004737",
}

export const CopyLinkColors = {
    "/portfolio/anandsweets": "#9C8EFF",
    "/portfolio/yogabar": "#AF5FFF",
    "/portfolio/nourysh": "#E1E4FF",
    "/portfolio/dr-vaidya": "#BDF0FF",
    "/portfolio/givernutritions": "#FFD4AC",
    "/portfolio/bayla": "#EFFFBE",
    "/portfolio/chhappanbhog": "#8DC0FF",
    "/portfolio/prakruti": "#90E3BD",
    "/portfolio/hyfun": "#DEFBCC",
    "/portfolio/hamdard": "#FBE5E6",
    "/portfolio/thenga": "#EDEAE0",
    "/portfolio/shistaka": "#F2E2BA",
    "/portfolio/sportsaber": "#EF6940",
    "/portfolio/dlfilms": "#F2E2BA",
    "/portfolio/ubar": "#F2E2BA",
    "/portfolio/freshleaf": "#F2E2BA",
    "/portfolio/radheysweet": "#F2E2BA",
    "/portfolio/healthoxide": "#F2E2BA",
    "/portfolio/maa-and-baby": "#F2E2BA",
    "/portfolio/gogrub": "#F2E2BA",
    "/portfolio/mypro": "#F2E2BA",
    "/portfolio/teatoxlife": "#F2E2BA",
    "/portfolio/ekkibekki": "#F2E2BA",
    "/portfolio/aumex": "#F2E2BA",
    "/portfolio/greenfay": "#F2E2BA",
    "/portfolio/paperclip-co": "#F2E2BA",
    "/portfolio/naturare": "#F2E2BA",
    "/portfolio/fast-n-fry": "#F2E2BA",
    "/portfolio/neoroots": "#F2E2BA",
    "/portfolio/within-beauty": "#AF5FFF",
    "/portfolio/uppercase": "#8AC5CF",
    "/portfolio/om-sweets": "#F0B3A4",
    "/portfolio/healing-hands": "#AAF9FF",
    "/portfolio/yo-gathiya": "#FCEED0",
    "/portfolio/codifi": "#A38DC9",
    "/portfolio/terahash-solutions": "#A4A4A4",
    "/portfolio/kutum-ayurveda": "#DCB793",
    "/portfolio/arospice": "#BED0C6",
    "/portfolio/fone-network": "#989AF4",
    "/portfolio/repeat-gud": "#FCEED0",
    "/portfolio/nurdle": "#959EAB",
    "/portfolio/aurolab": "#7FB3C0",
    "/portfolio/technosport": "#888888",
    "/portfolio/westerville": "#99BEE2",
    "/portfolio/myoa-cookies": "#F5DFBB",
    "/portfolio/sparkfusion": "#9E9E9E",
    "/portfolio/bontrue-furniture": "#AF827B",
    "/portfolio/bhagavad-gita": "#8D5F66",

    "/portfolio/ui/teatoxlife": "#737270",
    "/portfolio/ui/theone": "#737270",
    "/portfolio/ui/apple-elevators": "#737270",

    "/portfolio/label/teatoxlife": "#737270",
    "/portfolio/label/aumex": "#737270",
    "/portfolio/label/shishtaka": "#737270",
    "/portfolio/label/healing-hands": "#737270",
    "/portfolio/label/naturare": "#737270",
    "/portfolio/label/mypro": "#737270",
    "/portfolio/label/ras": "#737270",
    "/portfolio/label/moody": "#737270",
}

export const footerBG = {
    "/conversion-rate-optimization": "#004737",
}

export const footerColor = {
    "/conversion-rate-optimization": "#56F09F",
}

export const urls = {
    '/': '',
    '/contact': 'Contact',
    '/portfolio': 'Portfolio',
    '/service': 'Service',
    '/career': 'Career',
    '/about': 'About',
    '/clients': 'Clients',
    '/portfolio/anandsweets': 'Anand Sweets',
    '/portfolio/yogabar': 'Yogabar',
    '/portfolio/nourysh': 'Nourysh',
    '/portfolio/dr-vaidya': 'Dr. Vaidya',
    '/portfolio/givernutritions': 'Giver Nutritions',
    '/portfolio/bayla': 'Bayla Skin',
    '/portfolio/chhappanbhog': 'Chhappanbhog',
    '/portfolio/prakruti': 'Prakruti',
    '/portfolio/hyfun': 'Hyfun',
    '/portfolio/hamdard': 'Hamdard',
    '/portfolio/thenga': 'Thenga',
    '/portfolio/shistaka': 'Shistaka',
    '/portfolio/sportsaber': 'Sport Saber',
    "/portfolio/dlfilms": "DL Films",
    "/portfolio/ubar": "Ubar",
    "/portfolio/freshleaf": "Fresh Leaf",
    "/portfolio/radheysweet": "Radhey Sweet",
    "/portfolio/healthoxide": "Healthoxide",
    '/portfolio/maa-and-baby': 'Maa & Baby',
    '/portfolio/gogrub': 'Go Grub',
    "/portfolio/mypro": "My Pro",
    "/portfolio/teatoxlife": "TeaTox Life",
    '/portfolio/ekkibekki': 'Ekki Bekki',
    '/portfolio/aumex': 'Aumex',
    '/portfolio/greenfay': 'Green Fay',
    '/portfolio/paperclip-co': 'Paperclip & Co',
    '/portfolio/naturare': 'Naturare',
    '/portfolio/fast-n-fry': 'Fast & Fry',
    '/portfolio/neoroots': 'Neo Roots',
    "/portfolio/within-beauty": "Within Beauty",
    "/portfolio/uppercase": "Uppercase",
    "/portfolio/om-sweets": "Om Sweets & Snacks",
    "/portfolio/healing-hands": "Healing hands",
    "/portfolio/yo-gathiya": "Yo Gathiya",
    "/portfolio/codifi": "CodiFi",
    "/portfolio/terahash-solutions": "Terahash Solutions",
    "/portfolio/kutum-ayurveda": "Kutum Ayurveda",
    "/portfolio/arospice": "Arospice",
    "/portfolio/fone-network": "Fone Network",
    "/portfolio/repeat-gud": "Repeat Gud",
    "/portfolio/nurdle": "Nurdle",
    "/portfolio/aurolab": "AuroLab",
    "/portfolio/technosport": "Techno Sport",
    "/portfolio/westerville": "Westerville",
    "/portfolio/myoa-cookies": "Myoa Cookies",
    "/portfolio/sparkfusion": "Spark Fusion",
    "/portfolio/bontrue-furniture": "Bontrue Furniture",
    "/portfolio/bhagavad-gita": "Bhagavad-Gita",

    '/services/seo': 'SEO',
    '/services/webdevelopment': 'Web Development',
    '/services/shopify': 'Shopify',
    '/services/visualidentity': 'Visual Identity',
    '/services/uiux': 'UI-UX',
    '/services/webdesign': 'Web Design',
    '/services/forstartup': 'For Startup',
    '/services/abtesting': 'Ab Testing',
    '/services/3d-render': '3D Render',
    '/portfolio/branding/abox': 'aBox',
    '/portfolio/branding/terahash-solutions': 'Terahash Solutions',
    '/portfolio/branding/nourysh': 'Nourysh',
} 