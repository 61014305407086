import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";
// import useScrollRedirect from "../hooks/useScrollRedirect";

export default function PortfolioGreenFay() {

    // useScrollRedirect('/portfolio/gogrub')

    return (
        <>
            <Helmet>
                <title>GreenFay’s Online Journey | Success Stories: Frozen Foods | aBox Agency</title>
                <meta name="title" content="GreenFay’s Online Journey | Success Stories: Frozen Foods | aBox Agency" />
                <meta name="description" content="aBox and GreenFay have joined forces to revolutionize the farming industry. Our collaboration has resulted in a unique online platform that not only showcases GreenFay’s high-quality potato products but also symbolizes our shared commitment to innovation and excellence in the e-commerce space." />
                <meta name="keywords" content="GreenFay, aBox, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, Collaborations, Valued Partnerships, Farming, Food Processing, Frozen Foods, Fries, Potato chips, Potato Products, Success Stories, Web Design, Shopify Plus, Creative Solutions, aBox Web Agency, Online Portfolio, Web Development, E-commerce Success, Digital Marketing, Responsive Design, SEO Optimization, UI/UX, Development, Visual Identity, Onsite SEO, Graphic Design, Logo Design" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/greenfay" />
            </Helmet>

            <Header />

            <div className="page_bg bg_greenfay">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">Green Fay</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1703829122.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2023</p>
                                    <h1 className="services_title oxide_text mb-0">
                                        Digital Harvest: <br /> A Potato Odyssey
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">GreenFay, a respected brand in potato farming for over six decades, sought to expand into local markets with their potato products. They turned to aBox, our web agency, for guidance. We provided a comprehensive consultation, developed a Shopify website, and created a unique UI, graphic designs, and logo to represent their brand. Today, GreenFay’s live website is not just a business tool but a symbol of their ambition and our commitment to bringing visions to life.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://greenfay.com/" target="_blank" className="d-flex align-items-center text_shiska">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Development</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Farming and food processing pots.</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center greenfay_logo_bg">
                                <img src="/assets/image/portfolios/greenfay/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/greenfay/greenfay1.webp" className="w-100" alt="GreenFay" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Crafting GreenFay’s Vision with Precision and Expertise</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        Our tech wizards at aBox dedicated their expertise to bringing GreenFay’s vision into reality with meticulous precision. We’ve engineered a fluid online shopping experience with an intuitive interface, highlighting their wide array of enticing potato products. This Shopify website is a testament to GreenFay’s commitment to quality and customer satisfaction.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/greenfay/greenfay2.webp"
                                            alt="GreenFay" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/greenfay/greenfay3.webp"
                                            alt="GreenFay" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/greenfay/greenfay4.webp"
                                            alt="GreenFay" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/greenfay/greenfay5.webp"
                                            alt="GreenFay" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect greenfay_banner">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/greenfay/greenfay6.webp"
                                        alt="GreenFay" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/greenfay/greenfay7.webp"
                                        alt="GreenFay" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/greenfay/greenfay8.webp" className="w-100" alt="GreenFay color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/greenfay/greenfay9.webp" alt="GreenFay font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text_light perform_pera">Partnering with aBox transformed our business. Their expertise in building our Shopify website and creating a unique brand identity has been instrumental in our successful expansion into local markets. We highly recommend aBox for comprehensive web solutions.</p>
                                    <h3 class="title_color padd_s text_shiska">Sagar</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of GreenFay</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg paperclip_bg_img">
                    <div className="container">
                        <div className="text-center">
                            <a href="/portfolio/paperclip-co" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}