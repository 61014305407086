import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";
// import useScrollRedirect from "../hooks/useScrollRedirect";

export default function PortfolioArospice() {

    // useScrollRedirect('/portfolio/anandsweets')

    return (
        <>
            <Helmet>
                <title>Arospice’s Flavorful Digital Transformation | Success Stories: Indian Spices & Millets | aBox Agency</title>
                <meta name="title" content="Arospice’s Flavorful Digital Transformation | Success Stories: Indian Spices & Millets | aBox Agency" />
                <meta name="description" content="See how aBox gives the Arospice’s spiced up online presence. Our partnership created a dynamic platform showcasing Arospice’s premium Indian spices and millets, blending innovation with traditional goodness." />
                <meta name="keywords" content="Arospice, aBox, Shopify, E-commerce, Web Agency, Indian Spices, Millets, Web Development, Client Success Stories, Collaborations, Valued Partnerships, Natural Products, Success Stories, Web Design, Shopify Plus, Creative Solutions, aBox Web Agency, Online Portfolio, Web Development, E-commerce Success, Digital Marketing, Responsive Design, SEO Optimization, UI/UX, Development, Visual Identity, Onsite SEO, Graphic Design, Digital Transformation, Online Branding, Web Solutions, E-commerce Integration, Shopify Experts, Website Redesign, User Experience Design, Front-end Development, Back-end Development, Content Management System, Digital Marketing Services" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/arospice" />
            </Helmet>

            <Header />

            <div className="page_bg bg_arospice">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Arospice</p>
                                </div>
                                <div className="w-100">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">
                                        Arospice <br />
                                        <span className="arospice_title">
                                            Infusing Flavor into the Digital Realm
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white mb-3">Arospice, a beloved name in Indian Spices & Millets, was ready to spice up their online game. They came to us at aBox for a digital makeover, and we were thrilled to help! We revamped their website on Shopify, packed it with essential features, and ensured it was e-commerce ready. Plus, we gave their brand identity a fresh twist and established a strong online presence. Today, Arospice’s vibrant website is a testament to our shared passion for quality and flavor.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://onlinespicestore.com/" target="_blank" className="d-flex align-items-center arospice_title">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="arospice_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Visual Identity</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="arospice_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Indian Spices and Millets</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_arospice_logo">
                                <img src="/assets/image/portfolios/arospice/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/arospice/arospice1.webp" className="w-100" alt="arospice" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title arospice_title">Breathing New Life into Arospice’s Digital Presence</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        At aBox, our talented team worked their magic to give Arospice’s online presence a major upgrade. We created a visually stunning and super user-friendly Shopify website. The new site offers a seamless shopping experience, perfectly showcasing their rich variety of spices and millets.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/arospice/arospice2.webp"
                                        alt="Arospice" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/arospice/arospice3.webp"
                                            alt="Arospice" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/arospice/arospice4.webp"
                                        alt="Arospice" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/arospice/arospice5.webp"
                                        alt="Arospice" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect arospice_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/arospice/arospice6.webp"
                                            alt="Arospice" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/arospice/arospice7.webp"
                                            alt="Arospice" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title arospice_title">Where Tradition Meets Modern E-Commerce Magic</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Arospice, known for their top-notch Indian spices and millets, now has a digital home that blends traditional flavors with modern e-commerce flair. Visit <a href="https://onlinespicestore.com/" target="_blank" className="official_link omsweets_text">www.onlinespicestore.com</a> and dive into their world of aromatic spices and wholesome millets. It’s quality and authenticity you can taste with every click.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/arospice/arospice8.webp"
                                        alt="Arospice" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/arospice/arospice9.webp"
                                        alt="Arospice" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/arospice/arospice10.webp"
                                        alt="Arospice" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/arospice/arospice11.webp"
                                        alt="Arospice" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/arospice/arospice12.webp"
                                        alt="Arospice" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title arospice_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/arospice/arospice13.webp" className="w-100" alt="arospice color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/arospice/arospice14.webp" alt="arospice font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text-white perform_pera">aBox revitalized our online presence with a vibrant Shopify website that beautifully showcases our spices and millets. Their blend of traditional and modern e-commerce solutions has significantly enhanced our customer engagement.</p>
                                    <h3 class="title_color padd_s arospice_title">ShreeKumar CEO Founder</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Arospice</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg fone_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/fone-network" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}