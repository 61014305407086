import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import SiteLink from "../../component/icons/SiteLink";
import LetsTalk from "../../component/LetsTalk";
import PortfolioHorizontalSlider from "../../component/PortfolioHorizontalSlider";

export default function LabelAumex() {

    return (
        <>
            <Helmet>
                <title>Aumex Portfolio | Label Design & 3D Rendering Services | aBox Agency.</title>
                <meta name="title" content="Aumex Portfolio | Label Design & 3D Rendering Services | aBox Agency." />
                <meta name="description" content="Dive into our unique label designs, 3D models, infographics, and product graphics that echo the brand's ethos. displaying our design expertise through our work with Aumex, a leader in women's intimate hygiene products." />
                <meta name="keywords" content="Aumex, Women’s Health, Label Design, 3D Visualization, Infographics, aBox Agency, aBox design service, aBox label design, aBox 3D design services, Product Graphics, Packaging, 3D render, render, blender, Brand Collaboration, Digital Agency, Design Solutions, Portfolio Showcase, 3D Modeling, Comprehensive Services, Brand Identity, Intimate Care, Creative Designs, Packaging Resonance, Detail-Oriented, Hygiene care, Market Influence, Labeling, Product Design, Packaging Innovation, Labeling Solutions, 3D Product Modeling, Blender 3D Design, Creative Packaging, Innovative Labeling, 3D Render Services, Product Labeling, Custom Packaging, Blender Rendering, 3D Graphics, Product Label Design, Packaging Concepts, Blender Models, 3D Design Techniques, Labeling Standards, Packaging Trends" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/aumex" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">ECOMMERCE</p>
                                <h1 className="services_title mb-0"><span className="text_gred">Aumex</span> <br /> <span className="text_black">From Care to serve: <br /> Women Hygiene cure</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/aumex/aumex1.webp"
                        alt="aumex" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">Aumex is a leading brand in the women’s intimate hygiene sector. Our collaboration with Aumex involved a comprehensive suite of services. We crafted unique label designs, produced 3D renders, developed informative infographics, and designed product graphics for their entire product portfolio. Additionally, we also designed the packaging, ensuring it resonated with the brand’s core values. All our work was meticulously aligned with the brand’s essence, contributing to the ongoing success of Aumex in the market.</p>
                                <ul className="official_web">
                                    <li>
                                        <a href="https://aumex.in/" target="_blank" className="d-flex align-items-center contact_light">Official Website
                                            <SiteLink />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Packaging</li>
                                            <li>3D</li>
                                            <li>3D Modeling</li>
                                            <li>Product graphics</li>
                                            <li>Infographics</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Personal Care And Intimate Hygiene</li>
                                            <li>E-commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Ui section */}
                <div className="ui_section">
                    <div className="container">
                        <img src="/assets/image/label/aumex/aumex2.webp"
                            alt="aumex" className="img-fluid w-100" />

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/aumex/aumex3.webp" className="w-100" alt="aumex" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/aumex/aumex4.webp" className="w-100" alt="aumex" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/aumex/aumex5.webp" className="w-100" alt="aumex" />
                        </div>

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/aumex/aumex6.webp" className="w-100" alt="aumex" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/aumex/aumex7.webp" className="w-100" alt="aumex" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/aumex/aumex8.webp" className="w-100" alt="aumex" />
                        </div>

                        <div className="row mt_30 mb-4 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/aumex/aumex9.webp" className="w-100" alt="aumex" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="gap_remove">
                                    <img src="/assets/image/label/aumex/aumex10.webp" className="w-100" alt="aumex" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <PortfolioHorizontalSlider /> */}

            <LetsTalk />

            <Footer />
        </>
    );
}