import React from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { Helmet } from "react-helmet";

export default function Service() {

    function changeColor(...args) {
        document.getElementById("portfolio").style.backgroundColor = args[0];
        const elements = document.querySelectorAll(`.change-color`)
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.color = args[1]
        }
    }

    // useEffect(() => {
    //     if (window.innerWidth <= 1024) {
    //         const observerOptions = {
    //             root: null,
    //             rootMargin: '0px',
    //             threshold: 1, // 0.5 means 50% of the element is visible in the viewport
    //         };

    //         const observerCallback = (entries) => {
    //             entries.forEach((entry) => {
    //                 if (entry.isIntersecting) {
    //                     setMiddleSectionId(entry.target.id);
    //                 }
    //             });
    //         };

    //         const observer = new IntersectionObserver(observerCallback, observerOptions);

    //         const sections = document.querySelectorAll('.service_detail');
    //         sections.forEach((section) => observer.observe(section));

    //         return () => {
    //             sections.forEach((section) => observer.unobserve(section));
    //         };
    //     }
    // }, []);

    return (
        <>
            <Helmet>
                <title>Services | Expert Web Agency Services | Growth and Success | Box Web Agency</title>
                <meta name="title" content="Services | Expert Web Agency Services | Growth and Success | Box Web Agency" />
                <meta name="description" content="Discover Box Web Agency's expert services that drive growth and success. Explore our comprehensive solutions in web design, development, digital marketing, and more to elevate your online presence." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Web Agency Services, Expert Solutions, Growth and Success, Comprehensive Services, Web Design, Web Development, Online Branding, E-commerce Solutions, SEO Services, Responsive Design, User-Centric Approach, Creative Strategy, Conversion Optimization, Custom Development, Mobile-Friendly Solutions, Professional Consultation, Innovative Web Services, Result-Driven Solutions, Tailored Web Solutions, Business Expansion, Strategic Online Presence, Website Enhancement, Online Success, Client-Centered Approach" />
                <link rel="canonical" href="https://www.abox.agency/service" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="header_padding">
                <div className="section_detail service_section">
                    <div className="container">
                        <div className="row service_title">
                            <div className="col-12">
                                <p className="mb-1 font_medium">©2023</p>
                                <h1 className="portfolio_heading">Designing & Developing standout websites and branding using sound strategic thinking.</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <a href="#service" className="link-block-6 d-md-flex d-block">
                                    <div className="div-block-25">
                                        <Player
                                            autoplay
                                            loop
                                            src="/assets/image/Scroll-Down.json"
                                            style={{ height: '50px', width: '50px', margin: '-2px 0px 0px -12px' }}
                                        >
                                            <Controls visible={true} />
                                        </Player>
                                    </div>
                                    <p className="paragraph-2 font_medium d-block ps-md-4 ps-0 mt-md-0 mt-2">Scroll below to see our full range of agency services.</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* service section */}
            <div id="service" className="section_detail service-section">
                <div className="container">
                    {/* Shopify service */}
                    <div id="sec1" className="service_detail">
                        <a href="/services/shopify" className="service_link">
                            <div className="text-block-5">Shopify</div>
                            <div className="service">
                                <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                    <span className="text-block-4 margin-0">Shopify</span>
                                    <span className="text-block-4">Shopify plus</span>
                                </div>
                                <div className="div-block-13">
                                    <span className="text-block-4 margin-0">WooCommerce</span>
                                    <span className="text-block-4">Custom</span>
                                </div>
                            </div>
                        </a>
                        <div className="service-bg" />
                    </div>
                    {/* UI/UX service */}
                    <div id="sec2" className="service_detail">
                        <a href="/services/uiux" className="service_link">
                            <div className="text-block-5">UI/UX</div>
                            <div className="service">
                                <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                    <span className="text-block-4 margin-0">User Interface</span>
                                    <span className="text-block-4">User Experience</span>
                                    <span className="text-block-4">UI Design</span>
                                </div>
                                <div className="div-block-13">
                                    <span className="text-block-4 margin-0">Wireframes</span>
                                    <span className="text-block-4">Icons</span>
                                </div>
                            </div>
                        </a>
                        <div className="service-bg" />
                    </div>
                    {/* Visual Identity service */}
                    <div id="sec3" className="service_detail">
                        <a href="/services/visualidentity" className="service_link">
                            <div className="text-block-5">Visual Identity</div>
                            <div className="service">
                                <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                    <span className="text-block-4 margin-0">Research</span>
                                    <span className="text-block-4">Logo Design</span>
                                    <span className="text-block-4">Color Palettes</span>
                                </div>
                                <div className="div-block-13">
                                    <span className="text-block-4 margin-0">Typography</span>
                                    <span className="text-block-4">Digital Assets</span>
                                    <span className="text-block-4">Guidelines</span>
                                </div>
                            </div>
                        </a>
                        <div className="service-bg" />
                    </div>
                    {/* Web Design service */}
                    <div id="sec4" className="service_detail">
                        <a href="/services/webdesign" className="service_link">
                            <div className="text-block-5">Web Design</div>
                            <div className="service">
                                <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                    <span className="text-block-4 margin-0">Fast Load</span>
                                    <span className="text-block-4">Responsive</span>
                                    <span className="text-block-4">Cross Browser</span>
                                </div>
                                <div className="div-block-13">
                                    <span className="text-block-4 margin-0">Typography</span>
                                    <span className="text-block-4">SEO Consideration</span>
                                </div>
                            </div>
                        </a>
                        <div className="service-bg" />
                    </div>
                    {/* 3d render service */}
                    <div id="sec5" className="service_detail">
                        <a href="/services/3d-render" className="service_link">
                            <div className="text-block-5">3D Render</div>
                            <div className="service">
                                <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                    <span className="text-block-4 margin-0">3D Product</span>
                                    <span className="text-block-4">Modeling</span>
                                </div>
                                <div className="div-block-13">
                                    <span className="text-block-4 margin-0">Texturing</span>
                                    <span className="text-block-4">Post-production</span>
                                </div>
                            </div>
                        </a>
                        <div className="service-bg" />
                    </div>
                    {/* Development service */}
                    <div id="sec6" className="service_detail">
                        <a href="/services/webdevelopment" className="service_link">
                            <div className="text-block-5">Development</div>
                            <div className="service">
                                <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                    <span className="text-block-4 margin-0">Performance</span>
                                    <span className="text-block-4">Security</span>
                                    <span className="text-block-4">Testing</span>
                                </div>
                                <div className="div-block-13">
                                    <span className="text-block-4 margin-0">Deployment</span>
                                    <span className="text-block-4">Maintenance</span>
                                    <span className="text-block-4">Cross Browser</span>
                                </div>
                            </div>
                        </a>
                        <div className="service-bg" />
                    </div>
                    {/* Onsite SEO service */}
                    <div id="sec7" className="service_detail">
                        <a href="/services/seo" className="service_link">
                            <div className="text-block-5">Onsite SEO</div>
                            <div className="service">
                                <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                    <span className="text-block-4 margin-0">Onpage SEO</span>
                                    <span className="text-block-4">Meta Tags</span>
                                    <span className="text-block-4">URL Structure</span>
                                </div>
                                <div className="div-block-13">
                                    <span className="text-block-4 margin-0">Architecture</span>
                                    <span className="text-block-4">Keywords</span>
                                </div>
                            </div>
                        </a>
                        <div className="service-bg" />
                    </div>
                    {/* For Startup service */}
                    <div id="sec8" className="service_detail">
                        <a href="/services/forstartup" className="service_link">
                            <div className="text-block-5">For Startup</div>
                            <div className="service">
                                <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                    <span className="text-block-4 margin-0">Research</span>
                                    <span className="text-block-4">Strategy</span>
                                    <span className="text-block-4">Branding</span>
                                </div>
                                <div className="div-block-13">
                                    <span className="text-block-4 margin-0">Digital Solution</span>
                                    <span className="text-block-4">Marketing</span>
                                </div>
                            </div>
                        </a>
                        <div className="service-bg" />
                    </div>
                    {/* A/B Testing service */}
                    <div id="sec9" className="service_detail border-red">
                        <a href="/services/abtesting" className="service_link">
                            <div className="text-block-5 tab_color">A/B Testing</div>
                            <div className="service">
                                <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                    <span className="text-block-4 margin-0">Test Element</span>
                                    <span className="text-block-4">Rendomization</span>
                                    <span className="text-block-4">Testing Tool</span>
                                </div>
                                <div className="div-block-13">
                                    <span className="text-block-4 margin-0">Data Analysis</span>
                                    <span className="text-block-4">Continuous Testing</span>
                                </div>
                            </div>
                        </a>
                        <div className="service-bg bg-red" />
                    </div>
                </div>
            </div>

            {/* hire section */}
            <div className="hire-section">
                <div className="container">
                    <div className="div-block-34">
                        <div className="div-block-37">
                            <p className="paragraph-10">With our strategic approach, trusted by over 65 prestigious <br /> businesses we achieved a viral reach of 10 million, creating <br /> a buzz around our client's brand.</p>
                            <div className="div-block-29">
                                <a href="/contact" className="link-block-7">
                                    Way to go
                                    <img src="/assets/image/right_arrow.svg" loading="lazy" alt="right arrow" className="image-4" />
                                </a>
                                <div className="div-block-30" />
                                <div className="div-block-31" />
                                <div className="div-block-32" />
                                <div className="div-block-33"><img src="/assets/image/crown.svg" loading="lazy" alt="crown" /></div>
                            </div>
                        </div>
                        <a href="/contact" className="hire_link">
                            {/* <img src="/assets/image/left-top.svg" loading="lazy" alt="left top arrow" className="image-5 hire_arrow" />
                            <img src="/assets/image/right-top.svg" loading="lazy" alt="right top arrow" className="image-6" />
                            <div className="line_div">
                                <div className="cross_line" />
                            </div>
                            <img src="/assets/image/left-bottom.svg" loading="lazy" alt="left bottom arrow" className="image-7 hire_arrow" />
                            <img src="/assets/image/right-bottom.svg" loading="lazy" alt="right bottom arrow" className="image-8 hire_arrow" />
                            <div className="div-block-35">
                                <div className="text-block-6">hire us</div>
                            </div> */}
                            <svg id="Component_20_2" data-name="Component 20 – 2" xmlns="http://www.w3.org/2000/svg" width={200} height={200} viewBox="0 0 200 200">
                                <g id="Ellipse_257" data-name="Ellipse 257" transform="translate(0 0)" fill="none" stroke="#222" strokeWidth={1}>
                                    <circle cx={100} cy={100} r={100} stroke="none" />
                                    <circle cx={100} cy={100} r="99.5" fill="none" />
                                </g>
                                <g id="Ellipse_259" data-name="Ellipse 259" transform="translate(25 0)" fill="none" stroke="#222" strokeWidth={1}>
                                    <ellipse cx={75} cy={100} rx={75} ry={100} stroke="none" />
                                    <ellipse cx={75} cy={100} rx="74.5" ry="99.5" fill="none" />
                                </g>
                                <g id="Ellipse_260" data-name="Ellipse 260" transform="translate(54 0)" fill="none" stroke="#222" strokeWidth={1}>
                                    <ellipse cx={46} cy={100} rx={46} ry={100} stroke="none" />
                                    <ellipse cx={46} cy={100} rx="45.5" ry="99.5" fill="none" />
                                </g>
                                <g id="Ellipse_261" data-name="Ellipse 261" transform="translate(118 0) rotate(90)" fill="none" stroke="#222" strokeWidth={1}>
                                    <ellipse cx={100} cy={18} rx={100} ry={18} stroke="none" />
                                    <ellipse cx={100} cy={18} rx="99.5" ry="17.5" fill="none" />
                                </g>
                                <g id="Ellipse_258" data-name="Ellipse 258" transform="translate(0 70)" fill="#fff" stroke="#222" strokeWidth={1}>
                                    <ellipse cx={100} cy={30} rx={100} ry={30} stroke="none" />
                                    <ellipse cx={100} cy={30} rx="99.5" ry="29.5" fill="none" />
                                </g>
                                <text id="Hire_Us" data-name="Hire Us" transform="translate(51.657 109.433)" fill="#222" fontSize={35} fontFamily="Ubermove" fontWeight={400}><tspan x={0} y={0}>hire us</tspan></text>
                                <path id="Subtraction_6" data-name="Subtraction 6" d="M-1601.017-3392.768h-2.047V-3409.2H-1619.5v-2.047h18.482v18.481Z" transform="translate(1801.017 3411.25)" fill="#222" />
                                <path id="Subtraction_5" data-name="Subtraction 5" d="M-1617.2-3392.768h-2.047v-18.482h18.483v2.047H-1617.2v16.434Z" transform="translate(1619.25 3411.25)" fill="#222" />
                                <path id="Subtraction_4" data-name="Subtraction 4" d="M-1600.768-3393.017h-18.482V-3411.5h2.047v16.434h16.435v2.048Z" transform="translate(1619.25 3593.017)" fill="#222" />
                                <path id="Subtraction_3" data-name="Subtraction 3" d="M-1601.518-3393.518H-1619.5v-1.547h16.435V-3411.5h1.547v17.981Z" transform="translate(1801.518 3593.518)" fill="#222" />
                                <path id="Path_51" data-name="Path 51" d="M159.887,1.185l-17.3,17.3" transform="translate(39.057 0)" fill="none" stroke="#222" strokeWidth={2} />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

            {/* project section */}
            <div id="portfolio" className="portfolio_section service_padding">
                <div className="container">
                    <div className="row project-detail dark_border">
                        <div className="col-lg-6">
                            <h2 className="heading-6 color_dark change-color">Bursting with pride, glimpse of our remarkable works.</h2>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <p className="paragraph-11 color_dark font_light change-color">Radiating with client satisfaction, our work shines bright, a testament to our pride and joy, ready to be presented to you.</p>
                        </div>
                    </div>
                    <div className="div-block-40">
                        <div className="projects">
                            <div className="anand-sweets">
                                <a href="/portfolio/anandsweets" className="project-link" onMouseOver={() => { changeColor('#1B1830', '#9C8EFF') }}
                                    onMouseOut={() => {
                                        changeColor('#ffffff', '#222')
                                    }}>
                                    <img src="/assets/image/anand_sweets.webp" loading="lazy" alt="anand sweets" className="w-100" />
                                    <div className="div-block-42">
                                        <p className="project-name color_dark change-color">Anand sweets</p>
                                        <div className="line form_line" />
                                    </div>
                                    <h3 className="project-info color_dark change-color">Traditional Indian Sweets:<br className="d-lg-block d-none" /> A Taste of Royal India</h3>
                                    <p className="paragraph-13 color_dark change-color">Shopify, UI/UX, and SEO</p>
                                </a>
                            </div>
                        </div>
                        <div className="projects margin-top me-0">
                            <div className="anand-sweets">
                                <a href="/portfolio/yogabar" className="project-link" onMouseOver={() => { changeColor('#03063B', '#AF5FFF') }}
                                    onMouseOut={() => {
                                        changeColor('#ffffff', '#222')
                                    }}>
                                    <img src="/assets/image/yogabar.webp" loading="lazy" alt="yogabar" className="w-100" />
                                    <div className="div-block-42">
                                        <p className="project-name color_dark change-color">YogaBar</p>
                                        <div className="line form_line" />
                                    </div>
                                    <h3 className="project-info color_dark change-color">Healthy protein-based foods</h3>
                                    <p className="paragraph-13 color_dark change-color">Shopify, UI/UX, Graphic Designing,<br className="d-lg-block d-none" /> Site Maintenance</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="view_all">
                        <a href="/portfolio" className="viewall_a change-color">View all works
                            <img src="/assets/image/slider_next.svg" alt="View all portfolio" className="ms-3" />
                        </a>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}