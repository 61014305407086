import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";

export default function TerahashBranding() {

    return (
        <>
            <Helmet>
                <title>Terahash Solutions Branding | Bitcoin Mining | aBox Agency</title>
                <meta name="title" content="Terahash Solutions Branding | Bitcoin Mining | aBox Agency" />
                <meta name="description" content="Discover how aBox Agency transformed Terahash Solutions' brand with a complete refresh. From a bold logo and cohesive guidelines to stunning 3D visualizations, we enhanced their market presence in the Bitcoin mining industry." />
                <meta name="keywords" content="Terahash Solutions, Bitcoin mining, brand identity, web design agency, logo design, brand guideline, color palette, 3D product visualization, SEO, content marketing, aBox Agency, Bitcoin mining leader, ASIC miner sales, hosting, repairs, post-mining sales, Bitcoin mining services, web design firm, brand refresh, online presence, market position, target audience, competitive landscape, SEO-friendly brand identity, immersive experience, professional stationery, brand storytelling, brand messaging" />
                <link rel="canonical" href="https://abox.agency/portfolio/branding/terahash-solutions" />
            </Helmet>

            <Header />

            <div className="page_bg bg_terahash">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name text-white brand_name">Terahash Solutions</p>
                                </div>
                                <div className="w-100 position-relative">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="/assets/image/portfolios/abox/abox.mp4" />
                                    </video>
                                    <div className="brand_logo">
                                        <img src="/assets/image/portfolios/terahash_brand/logo.svg" className="banner_logo w-100" alt="Logo" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name writing_mod text-white brand_name">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">Terahash Solutions <br /> <span className="text-white">Redefining Brand Identity for Mining Excellence</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-3">aBox Agency, a leading web design firm, partnered with Terahash Solutions, a prominent Bitcoin mining service provider, to forge a powerful brand identity. This project aimed to elevate Terahash's online presence and solidify their market position.</p>
                                    <p className="services_pera text-white mb-3">Terahash Solutions faced challenges with an inconsistent brand image that affected their online visibility. We addressed this by delivering a cohesive brand identity, including a distinctive logo, detailed brand guidelines, and engaging visuals. Our efforts significantly strengthened their market presence.</p>
                                    <p className="services_pera text-white">To further enhance Terahash's online presence, we created stunning 3D product visualizations of their ASIC miners. This immersive experience allows potential customers to engage with Terahash's technology in a captivating way. Professional stationery completed the cohesive brand, leaving a lasting impression.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <img src="/assets/image/portfolios/terahash_brand/terahash1.webp" className="banner_logo w-100" alt="Logo" loading="lazy" />
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/terahash_brand/terahash2.webp" className="w-100" alt="Terahash Solutions" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">A Strong, User-Friendly Brand Identity</h2>
                                    <p className="services_pera text-white font_regular mb-0">Terahash Solutions now boasts a powerful and user-friendly brand identity. This strategic identity positions them for continued success in the competitive Bitcoin mining landscape.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gap_10 gaps_columns">
                                    <img src="/assets/image/portfolios/terahash_brand/terahash3.webp" alt="Terahash Solutions" className="w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/terahash_brand/terahash4.webp" alt="Terahash Solutions" className="w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/terahash_brand/terahash5.webp" alt="Terahash Solutions" className="w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/terahash_brand/terahash6.webp" alt="Terahash Solutions" className="w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/terahash_brand/terahash7.webp" alt="Terahash Solutions" className="w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/terahash_brand/terahash8.webp" alt="Terahash Solutions" className="w-100" loading="lazy" />
                                </div>
                            </div>
                            <div className="strenth_bg brand_img p-0 bg_transparent mt_10">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/terahash_brand/terahash9.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/terahash_brand/terahash10.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" loading="lazy" />
                                </div>
                            </div>
                            <div className="pb-5 mt_10">
                                <img src="/assets/image/portfolios/terahash_brand/terahash11.webp" alt="Terahash Solutions" className="w-100" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}