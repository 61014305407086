import React, { useEffect, useRef } from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import PortfolioHorizontalSlider from "../component/PortfolioHorizontalSlider";

gsap.registerPlugin(ScrollTrigger);

export default function ServiceWebDesign() {

    const section1Ref = useRef(null);
    const colLeftRef = useRef(null);

    useEffect(() => {
        if (window.innerWidth > 1024) {
            function raf(time) {
                ScrollTrigger.update();
                requestAnimationFrame(raf);
            }

            requestAnimationFrame(raf);

            const section1 = section1Ref.current;
            const colLeft = colLeftRef.current;
            const timeline = gsap.timeline({ paused: true });

            timeline.fromTo(
                colLeft,
                { y: 0 },
                { y: '155vh', duration: 1, ease: 'none' },
                0
            );

            ScrollTrigger.create({
                animation: timeline,
                trigger: section1,
                start: 'top top',
                end: 'bottom center',
                scrub: true,
            });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Custom Web Design Solutions for Your Business | Box Web Agency</title>
                <meta name="title" content="Custom Web Design Solutions for Your Business | Box Web Agency" />
                <meta name="description" content="Elevate your business with custom web design solutions from Box Web Agency. Our tailored designs enhance your online presence, ensuring a seamless and appealing user experience." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Custom Web Design Solutions, Business Web Design, Tailored Design Services, Web Agency Design, Online Presence Enhancement, Web Design Expertise, Business Branding, User-Centric Design, Professional Web Design, Creative Design Solutions, Web Design Excellence, Business Growth through Design, Unique Website Design, Responsive Web Design, Seamless User Experience, Web Design and Branding, User-Friendly Design, Web Design Consultation, Business Web Solutions, Web Design Innovation, Visual Appeal, Design for Success, Customized Web Solutions, Web Agency Services, Designing Your Business Future" />
                <link rel="canonical" href="https://www.abox.agency/services/webdesign" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="header_padding">
                <div className="section_detail">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-7 sec_padding">
                                <h1 className="service_heading">Web Designing</h1>
                                <p className="seo_p">Discover the power of captivating web design! At aBox Agency, we blend art and technology to create stunning websites that drive results. Boost your online presence, engage your audience, and unlock endless possibilities for your business. Let's shape your digital success together!</p>
                            </div>
                            <div className="col-lg-5 pe-0 text-end ps-lg-3 ps-0">
                                <div>
                                    <img src="/assets/image/service/design.webp" alt="design" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* seo detail section */}
            <div className="section_detail seo_section">
                <div className="container">
                    <div className="row padding_bottom border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Stunning Websites that Captivate your Audience</h2>
                        </div>
                        <div className="col-xl-6">
                            <p className="seo_content">Whether you're a small business or a big enterprise, we cover all your requirements. We specialize in crafting stunning websites that captivate and mesmerize your audience. Ignite engagement, spark curiosity, and unleash your online potential with our exceptional web design services.</p>
                        </div>
                    </div>
                    <div className="row feature_padding border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">The best features We provide</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex flex-wrap">
                                <p className="feature_p">User-Centricity</p>
                                <p className="feature_p">Responsive Design</p>
                                <p className="feature_p">Intuitive Navigation</p>
                                <p className="feature_p">Visual Consistency</p>
                                <p className="feature_p">Accessibility Compliance</p>
                                <p className="feature_p">Mobile Optimization</p>
                                <p className="feature_p">Content Readability</p>
                                <p className="feature_p">Performance Optimization</p>
                            </div>
                        </div>
                    </div>
                    <div className="row feature_padding pb-0">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Why aBox?</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Innovation</h3>
                                    <p className="seo_content mb-0 font_light">We understand the importance of bringing newness to your web store. Through innovative web design, we incorporate fresh and captivating elements, modern aesthetics, and user-friendly features to enhance your online presence and drive customer engagement.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Planning</h3>
                                    <p className="seo_content mb-0 font_light">At our web design agency, we recognize the significance of planning for your web store's success. We meticulously strategize and execute design blueprints, wireframes, and user flows to ensure a seamless and optimized online shopping experience.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Responsiveness</h3>
                                    <p className="seo_content mb-0 font_light">Ensuring responsiveness is key to knowing the right execution for your website. We employ techniques such as fluid grids, flexible images, and media queries, along with thorough testing, to guarantee a flawless user experience across devices.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Implementation</h3>
                                    <p className="seo_content mb-0 font_light">The implementation is a big part of the web store. After pre-planning and execution, Implementations give us insight into the website. We excel at translating design concepts into reality, ensuring seamless integration of features, functionality, and visual elements for an exceptional online shopping experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* image section */}
            <div className="section_detail image_section detail_section webdesign" ref={section1Ref}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" ref={colLeftRef}>
                            <h2 className="service_h2 title_margin">Transform your ideas into<br className="d-md-block d-none" />
                                stunning web designs.</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/1</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Research and Analysis</h3>
                                    <p className="services_p">First things first, We employ a comprehensive approach to technology research. We meticulously analyze emerging web design trends, innovative frameworks, and cutting-edge tools. Our team stays abreast of advancements in front-end and back-end technologies, ensuring we leverage the most suitable solutions for your website. By combining our expertise with thorough research, we deliver modern, efficient, and high-performing web designs that elevate your online presence and drive meaningful results.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/2</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Design and Frameworks</h3>
                                    <p className="services_p">After Relevant research, We excel in technology and web frame design with a strong focus on User Experience (UX). Our team combines intuitive interfaces, seamless navigation, and user-centered design principles to create engaging websites. The benefits of UX include enhanced customer satisfaction, increased conversions, improved brand loyalty, and reduced bounce rates. Our UX-driven approach ensures websites that are user-friendly, visually appealing, and optimized for optimal performance and results.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/3</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Development</h3>
                                    <p className="services_p">Once research and design were done, We leveraged innovative technologies to develop websites. We employ robust frameworks, integrate powerful APIs, and utilize efficient coding practices to create functional and dynamic websites. This approach enhances the user experience, improves website performance, and allows for seamless scalability, ultimately maximizing your online presence and driving business growth.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/4</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Execution</h3>
                                    <p className="services_p">Execution is paramount to your web store's success. After Planning, Analysis, and development, the next important step is to execute the plan as per all the requirements. And we flawlessly translate your vision into reality by implementing technologies, incorporating intuitive navigation, optimizing performance, and integrating secure payment gateways. We prioritize seamless user experiences, visually captivating designs, and responsive layouts to ensure your web store thrives in the competitive digital landscape.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/5</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Incorporate Mechanisms</h3>
                                    <p className="services_p">We Always focus on seamlessly integrating mechanisms into front-end development. Our expert team combines cutting-edge technologies and creative expertise to deliver engaging user interfaces, responsive designs, smooth navigation, interactive elements, and optimized performance. Elevate your website's user experience with our tailored front-end solutions that leave a lasting impression.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">W/6</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Latest Variations</h3>
                                    <p className="services_p">Keeping your website fresh and up-to-date Our team provides ongoing updates, incorporating the most recent trends and technologies to improve user experiences, improve functionality, and keep your online presence at the cutting edge of innovation. Trust us to keep your website ahead of the curve.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PortfolioHorizontalSlider />

            <LetsTalk />

            <Footer />
        </>
    );
}