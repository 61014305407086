import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";
import PortfolioHorizontalSlider from "../../component/PortfolioHorizontalSlider";

export default function UIHealingHands() {

    return (
        <>
            <Helmet>
                <title>Healing Hands UI Design | UI/UX Design Portfolio | aBox Agency</title>
                <meta name="title" content="Healing Hands UI Design | UI/UX Design Portfolio | aBox Agency" />
                <meta name="description" content="Explore Healing Hands UI Portfolio showcasing innovative UI/UX designs focused on health and wellness. Discover user-centric interfaces that elevate the digital healthcare experience." />
                <meta name="keywords" content="UI/UX Design, Healing Hands, Portfolio, abox, abox agency, Shopify Shopify UIUX, Creative Designs, UI, UX, UIUX, UI/UX, User experience solutions, User-Centric Interfaces, Digital Wellness, Health and Wellness, Healthcare Design, UI/UX Portfolio, UI/UX Showcase, Healing Hands Portfolio, Innovative Designs, User Experience, Web Design, Graphic Design, Health Tech, User Interface, Digital Health, Healing Design Solutions, Healthcare UX, Creative Portfolio, Digital Transformation, Visual Design, User Interaction, Interactive Designs" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/ui/healing-hands" />
            </Helmet>

            <Header />

            <div className="main_page bg-white">
                <div className="product_banner">
                    <img src="/assets/image/ui/healinghand/healing1.webp"
                        alt="healing hands" className="img-fluid w-100" />
                </div>

                <div className="mt_20">
                    <img src="/assets/image/ui/healinghand/healing2.webp"
                        alt="healing hands" className="img-fluid w-100" />
                </div>

                <div className="mt_20">
                    <img src="/assets/image/ui/healinghand/healing3.webp"
                        alt="healing hands" className="img-fluid w-100" />
                </div>

                <div>
                    <img src="/assets/image/ui/healinghand/healing4.webp"
                        alt="healing hands" className="img-fluid w-100" />
                </div>

                <div>
                    <img src="/assets/image/ui/healinghand/healing5.webp" className="w-100" alt="healing hands" />
                </div>

                <div className="mt_20">
                    <img src="/assets/image/ui/healinghand/healing6.webp" className="w-100" alt="healing hands" />
                </div>
            </div>

            {/* <PortfolioHorizontalSlider /> */}

            <LetsTalk />

            <Footer />
        </>
    );
}