import React, { useState } from "react";
import { backgroundColors, buttonColors, buttonFontColors, fontColors, svgColors, urls } from "../mappings";

export default function Header() {

    const [hoverState, setHoverState] = useState(null)
    const [toggle, setToggle] = useState(false)

    function titleCase(str) {
        return str?.toLowerCase().split('/').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join('/');
    }

    return (
        <>
            <header className="header sticky">
                <nav role="navigation" className="navbar" id="nav" style={{ backgroundColor: backgroundColors[window.location.pathname.toLowerCase()] || "#ffffff" }}>
                    <div className="container pe-md-0">
                        <a href="/">
                            <svg width="80" height="37" viewBox="0 0 80 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path style={{ fill: svgColors[window.location.pathname.toLowerCase()] || "#222" }} d="M80 37H0V0H73.8457V6.16667H80V37ZM61.5383 24.6667H67.6926V30.8333H73.8469V24.6667H67.6926V18.5H61.5383V6.16667H55.384V18.5H61.5383V24.6667H55.384V30.8333H61.5383V24.6667ZM30.7691 6.16667V30.8333H49.2309V12.3333H43.0766V6.16667H30.7691ZM6.15429 6.16667V30.8333H24.616V18.5H18.4617V12.3333H12.3074V6.16667H6.15429ZM73.8457 12.3333H67.6926V18.5H73.8469L73.8457 12.3333ZM43.0766 24.6667H36.9234V12.3333H43.0766V24.6667ZM18.4617 24.6667H12.3074V18.5H18.4617V24.6667Z" fill="#222222" />
                            </svg>
                        </a>

                        <div className="d-flex align-items-center">
                            <p style={{ color: fontColors[window.location.pathname.toLowerCase()] || "#222" }} className="url_p d-md-block d-none">{urls[window.location.pathname.toLowerCase()]}</p>

                            <div id="menu" className="mx-md-5 mx-2" onClick={() => document.getElementById('disablescroll')?.classList.add('sdisable')} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="8.024" viewBox="0 0 35 8.024">
                                    <g id="Group_13" data-name="Group 13" transform="translate(-1736.392 -78.876)">
                                        <rect style={{ fill: svgColors[window.location.pathname.toLowerCase()] || "#222" }} id="Rectangle_16" data-name="Rectangle 16" width="35" height="2" transform="translate(1736.392 78.876)" fill="#222" />
                                        <rect style={{ fill: svgColors[window.location.pathname.toLowerCase()] || "#222" }} id="Rectangle_17" data-name="Rectangle 17" width="35" height="2" transform="translate(1736.392 84.9)" fill="#222" />
                                    </g>
                                </svg>
                            </div>

                            <div className="d-md-flex d-none">
                                <a href="/portfolio" className="url_a bg-red">
                                    <img src="/assets/image/portfolio_text.svg" alt="aBox portfolio" />
                                </a>
                                <a href="/contact" className="url_a" style={{ backgroundColor: buttonColors[window.location.pathname.toLowerCase()] || "#417B5A", color: buttonFontColors[window.location.pathname.toLowerCase()] || "#EFEBE7" }}>Have an idea?</a>
                            </div>
                        </div>

                        <div id="navbarSupportedContent" className="drop_menu collapse nav-menu">
                            <div className="nav_bg">
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-between animation_up animation_down">
                                        <a href="/" className="logo_dark">
                                            <img src="/assets/image/footer_logo.svg" alt="Logo" />
                                        </a>
                                        <div id="menu" className="menu_close position-relative" data-bs-toggle="collapse" onClick={() => document.getElementById('disablescroll')?.classList.remove('sdisable')} data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            {/* <img src="/assets/image/menu_close.svg" alt="Menu close" /> */}
                                            <span className="close_arrow"></span>
                                            <span className="close_arrow mb-0"></span>
                                        </div>
                                    </div>
                                    <div className="d-flex menu_height flex-column justify-content-between h-100">
                                        <div className="row menu_padding menu_animation">
                                            <div className="col-md-4 animation_up animation_down">
                                                <a href="/" onMouseOver={() => setHoverState(0)} style={{ opacity: hoverState === null || hoverState === 0 ? 1 : 1 / 2 }} onMouseOut={() => setHoverState(null)} className={!toggle ? "nav-link" : "nav-link navlink_open"}>Home</a>
                                            </div>
                                            <div className="col-md-4 animation_up animation_down">
                                                <a href="/service" onMouseOver={() => setHoverState(3)} style={{ opacity: hoverState === null || hoverState === 3 ? 1 : 1 / 2 }} onMouseOut={() => setHoverState(null)} className={!toggle ? "nav-link" : "nav-link navlink_open"}>Services<sup>11</sup></a>
                                            </div>
                                            <div className="col-md-4 animation_up animation_down">
                                                <a href="/portfolio" onMouseOver={() => setHoverState(1)} style={{ opacity: hoverState === null || hoverState === 1 ? 1 : 1 / 2 }} onMouseOut={() => setHoverState(null)} className={!toggle ? "nav-link" : "nav-link navlink_open"}>Portfolio<sup>52</sup></a>
                                            </div>
                                            <div className="col-md-4 animation_up animation_down">
                                                <a href="/blogs" onMouseOver={() => setHoverState(5)} style={{ opacity: hoverState === null || hoverState === 5 ? 1 : 1 / 2 }} onMouseOut={() => setHoverState(null)} className={!toggle ? "nav-link" : "nav-link navlink_open"}>Blogs</a>
                                            </div>
                                            <div className="col-md-4 animation_up animation_down">
                                                <a href="/contact" onMouseOver={() => setHoverState(2)} style={{ opacity: hoverState === null || hoverState === 2 ? 1 : 1 / 2 }} onMouseOut={() => setHoverState(null)} className={!toggle ? "nav-link" : "nav-link navlink_open"}>Contact</a>
                                            </div>
                                            <div className="col-md-4 animation_up animation_down">
                                                <a href="/about" onMouseOver={() => setHoverState(4)} style={{ opacity: hoverState === null || hoverState === 4 ? 1 : 1 / 2 }} onMouseOut={() => setHoverState(null)} className={!toggle ? "nav-link" : "nav-link navlink_open"}>About</a>
                                            </div>
                                        </div>
                                        <div className="row mb-md-0 mb-4 animation_up animation_down address_section">
                                            <div className="col-lg-4 mb-md-0 mb-3  col-md-5">
                                                <p className="menu_p">Surat, India</p>
                                                <p className="color_light font_light">F-02, ORBIT - 1, Punagam-Saroli Road, <br /> Surat, Gujarat 395010</p>
                                            </div>
                                            <div className="col-lg-3 col-md-5 offset-lg-5 mt-md-0 mt-4">
                                                <div className="mb-3">
                                                    <a href="https://www.instagram.com/aboxagency/?igshid=MzRlODBiNWFlZA==" target="_blank" className="menu_p me-4 mb-0">Ig</a>
                                                    <a href="https://www.facebook.com/profile.php?id=100094598875876" target="_blank" className="menu_p me-4 mb-0">Fb</a>
                                                    <a href="https://dribbble.com/aboxagency" target="_blank" className="menu_p me-4 mb-0">Db</a>
                                                    <a href="https://www.behance.net/aboxagency" target="_blank" className="menu_p me-4 mb-0">Be</a>
                                                    <a href="https://twitter.com/aBox_Agency" target="_blank" className="menu_p mb-0">X</a>
                                                    {/* <a href="https://www.linkedin.com/company/abox-agency/" target="_blank" className="menu_p mb-0">In</a> */}
                                                </div>
                                                <div>
                                                    <a href="sitemap.xml" target="_blank" className="menu_p mb-0">Sitemap</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-md-none d-flex w-100">
                        <a href="/portfolio" className="url_a bg-red">
                            <img src="/assets/image/portfolio_text.svg" alt="aBox portfolio" />
                        </a>
                        <a href="/contact" className="url_a" style={{ backgroundColor: buttonColors[window.location.pathname.toLowerCase()] || "#417B5A", color: buttonFontColors[window.location.pathname.toLowerCase()] || "#EFEBE7" }}>Have an idea?</a>
                    </div>
                </nav>
            </header>
        </>
    );
}