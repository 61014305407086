import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ContactForm from "../component/ContactForm";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function InternationalCampaignB() {

    const clientReview = [
        {
            title: 'Stunning Website',
            review: 'aBox created a stunning website, capturing our brand, showcasing technical skill, and exceeding expectations for an exceptional shopping experience.',
            clientname: 'Ankush Dadu',
            clientcompany: 'Anand Sweet'
        },
        {
            title: 'Online Transformation',
            review: `aBox Agency transformed Yoga Bar's online presence with expert website development, SEO, captivating content, and commendable dedication. Highly recommended.`,
            clientname: 'Suhasini',
            clientcompany: 'Yoga Bar'
        },
        {
            title: 'Great Website Capture',
            review: 'The Box Agency delivered a delightful experience, capturing our brand essence in a visually stunning website, exceeding expectations with professionalism.',
            clientname: 'Amin Harshil',
            clientcompany: `Patel's Chhappanbhog`
        },
        {
            title: 'Exceeded Expectations',
            review: `They really surpassed our expectations by revamping our online presence for our Skincare's products and now we're so delightful work with them, understanding our brand, and creating a perfect website.`,
            clientname: 'Sai',
            clientcompany: 'Bayla Skincare'
        },
        {
            title: 'Clear Brand Presentation',
            review: `Working with aBox was delightful. They captured our brand essence in a visually captivating website, showing technical expertise and professionalism. as the India's oldest brand they still present our vision and ideas most clear way possible.`,
            clientname: 'Ahmed Hameed',
            clientcompany: 'Hamdard'
        },
        {
            title: 'Professional and Dedicated',
            review: 'These peoples beautifully represented our natural product brand online, demonstrating technical expertise and dedication to excellence. Surely suggestion for professionalism.',
            clientname: 'Maria Kuriakose',
            clientcompany: 'Thenga Coco'
        }
    ]

    return (
        <>
            <Helmet>
                <title>Shopify Web Development Packages | Best E-commerce Solutions | Box Web Agency</title>
                <meta name="title" content="Shopify Web Development Packages | Best E-commerce Solutions | Box Web Agency" />
                <meta name="description" content="Discover our Shopify website and store packages, and explore the comprehensive web development details we offer. Get started on your e-commerce journey with tailored solutions for your online store needs." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Online store, Pricing, Packages, Shopify pricing, Wordpress store, wordpress Ecommerce, Shopify Packages, Shopify E-commerce store, A/B testing, Shopify, E-commerce, Web Development, Website Marketing Page, Shopify Packages, E-commerce Solutions, Web Development Services, Online Store Development, Website Design, Shopify Experts, E-commerce Solutions, Online Retail, Custom Web Development, Responsive Web Design, Shopify Store Design, Shopify Themes, Website Maintenance, SEO Optimization, Mobile-Friendly Design, Shopify Apps, Conversion Rate Optimization, User Experience (UX), Shopify Plus Services, Digital Marketing Strategy" />
                <link rel="canonical" href="https://www.abox.agency/international-campaign-b" />
            </Helmet>

            <GoogleReCaptchaProvider
                reCaptchaKey="6LfdHy0pAAAAAKE3pfhPaTjJqt9vgHye5CGL-jZz"
            >

                <Header />

                <div className="section_detail position-relative marketing_bg">
                    <div className="container-fluid">
                        <div className="row justify-content-xl-start justify-content-center">
                            <div className="col-xl-7 col-12 marketing_padding">
                                <p className="shopify_bg mb-2">Shopify</p>
                                <h1 className="marketing_title">Ecommerce Development Agency</h1>
                                <p className="marketing_p">Boost your online business with our Shopify web experts. We create custom websites <br className="d-lg-block d-none" /> that are easy to use and bring in more sales.</p>
                                <div>
                                    <div className="d-flex align-items-center mb-3">
                                        <img src="/assets/image/marketing/marketing_arrow.svg" alt="marketing arrow" />
                                        <p className="marketing_p my-0 ms-3">Increase user experience for higher customer satisfaction.</p>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <img src="/assets/image/marketing/marketing_arrow.svg" alt="marketing arrow" />
                                        <p className="marketing_p my-0 ms-3">Customized designs made for your unique brand.</p>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <img src="/assets/image/marketing/marketing_arrow.svg" alt="marketing arrow" />
                                        <p className="marketing_p my-0 ms-3">Mobile-friendly, reaching users on any device.</p>
                                    </div>
                                    <div className="d-flex align-items-center mb-5">
                                        <img src="/assets/image/marketing/marketing_arrow.svg" alt="marketing arrow" />
                                        <p className="marketing_p my-0 ms-3">Increased sales through high-converting website solutions.</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center gap_40">
                                    <a href="/contact" className="btn submit_btn marketing_btn">Talk With Our Expert
                                        <svg className="ms-2 submit_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#ffffff" />
                                        </svg>
                                        <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#AA5042" />
                                        </svg>
                                    </a>
                                    <div>
                                        <a href="https://api.whatsapp.com/send?phone=916354887997&text=Hello%2C%0A%0A" target="_blank" className="live_chat live_chat_link">
                                            <img src="/assets/image/marketing/live_chat.svg" alt="live chat icon" className="me-2" />Live Chat
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 text-center d-xl-none d-md-block">
                                <img src="/assets/image/marketing/m_mobile.webp" className="w-100" alt="Marketing" />
                            </div>
                        </div>
                        <a href="#" className="btn work_btn d-none">Work with us</a>
                    </div>
                </div>

                <div className="section_detail image_section bg_dark">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="marketing_title text-white pb-md-3">About Us</h2>
                                <p className="marketing_p text-white">We're a team at aBox, making your online success simple. We create custom websites that help your business grow. Our mission is to support your online journey. Let's work together to boost your brand's online presence and success.</p>
                            </div>
                            <div className="col-12 mt-lg-5 mt-4">
                                <div className="row about_store">
                                    <div className="col-lg-3 col-6">
                                        <h3 className="marketing_title text-white">400 +</h3>
                                        <p className="marketing_p text-white mt-2 mb-0">Shopify Stores</p>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <h3 className="marketing_title text-white ms-md-5">100 +</h3>
                                        <div className="d-flex align-items-center">
                                            <span className="marketing_line d-md-block d-none"></span>
                                            <p className="marketing_p text-white mt-2 mb-0 ms-md-5">Team Experts</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <h3 className="marketing_title text-white ms-lg-5">20 +</h3>
                                        <div className="d-flex align-items-center">
                                            <span className="marketing_line d-lg-block d-none"></span>
                                            <p className="marketing_p text-white mt-2 mb-0 ms-lg-5">Country Served</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-6">
                                        <h3 className="marketing_title text-white ms-md-5">$4M +</h3>
                                        <div className="d-flex align-items-center">
                                            <span className="marketing_line d-none d-md-block"></span>
                                            <p className="marketing_p text-white mt-2 mb-0 ms-xl-5 ms-lg-4 ms-md-5">Shopify Revenue</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_detail product_services image_section position-relative">
                    <img src="/assets/image/marketing/shopify_partners.svg" alt="shopify" className="shopify_partner" />
                    <div className="container">
                        <div className="row text-start">
                            <div className="col-12">
                                <h2 className="marketing_title pb-md-3">Our Services</h2>
                            </div>
                            <div className="col-lg-10">
                                <p className="marketing_p mt-4 mb-5">Unlock brand potential with our services. Elevate your online presence, engage customers with stunning design, and boost income through complete Shopify development, UI/UX, and branding.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 d-flex flex-column">
                                <img src="/assets/image/marketing/uiux.webp" alt="uiux" className="w-100 mb-lg-3 order-2 order-lg-1" />
                                <p className="marketing_service text-start order-1 order-lg-2 mb-lg-0 mb-3">UIUX</p>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-md-0 mt-5 d-flex flex-column">
                                <img src="/assets/image/marketing/visual_identity.webp" alt="visual identity" className="w-100 mb-lg-3 order-2 order-lg-1" />
                                <p className="marketing_service text-start order-1 order-lg-2 mb-lg-0 mb-3">Visual Identity</p>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-lg-0 mt-5 d-flex flex-column">
                                <img src="/assets/image/marketing/shopify.webp" alt="shopify" className="w-100 mb-lg-3 order-2 order-lg-1" />
                                <p className="marketing_service text-start order-1 order-lg-2 mb-lg-0 mb-3">Shopify</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_detail image_section pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="marketing_title pb-md-3">Portfolio</h2>
                            </div>
                            <div className="col-lg-8">
                                <p className="marketing_p mt-4 mb-5">Discover our portfolio: a showcase of successful projects and collaborations. Witness real growth in branding, design, and development—your brand's potential is our passion.</p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="padding_left">
                            <Swiper
                                // slidesPerView={1}
                                speed={2000}
                                autoHeight={true}
                                // spaceBetween={0}
                                autoplay={false}
                                navigation={false}
                                pagination={false}
                                draggable={true}
                                loop={false}
                                breakpoints={{
                                    375: {
                                        slidesPerView: 1.01,
                                    },
                                    430: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 10,
                                    },
                                    576: {
                                        slidesPerView: 1.4,
                                    },
                                    768: {
                                        slidesPerView: 1.6,
                                    },
                                    820: {
                                        slidesPerView: 1.8,
                                    },
                                    991: {
                                        slidesPerView: 2.1,
                                    },
                                    1024: {
                                        slidesPerView: 2.3,
                                    },
                                    1200: {
                                        slidesPerView: 2.7,
                                    },
                                    1366: {
                                        slidesPerView: 3,
                                    },
                                    1400: {
                                        slidesPerView: 2.9,
                                    },
                                    1440: {
                                        slidesPerView: 3.2,
                                        spaceBetween: 20,
                                    },
                                    1600: {
                                        slidesPerView: 3.8,
                                    },
                                    1920: {
                                        slidesPerView: 4.2,
                                        spaceBetween: 30,
                                    },
                                }}
                                modules={[Navigation, Pagination, Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className="screen">
                                        <img src="/assets/image/marketing/anand_sweets.webp" alt="anand sweets" className="w-100" loading="lazy" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="screen screen2">
                                        <img src="/assets/image/marketing/green_fay.webp" alt="green fay" className="w-100" loading="lazy" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="screen screen3">
                                        <img src="/assets/image/marketing/hamdard.webp" alt="hamdard" className="w-100" loading="lazy" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="screen screen4">
                                        <img src="/assets/image/marketing/neo_roots.webp" alt="neo roots" className="w-100" loading="lazy" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="screen screen5">
                                        <img src="/assets/image/marketing/nourish.webp" alt="nourysh" className="w-100" loading="lazy" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="screen screen6">
                                        <img src="/assets/image/marketing/prakruti.webp" alt="prakruti" className="w-100" loading="lazy" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="screen screen7">
                                        <img src="/assets/image/marketing/shishtaka.webp" alt="shishtaka" className="w-100" loading="lazy" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="screen screen8">
                                        <img src="/assets/image/marketing/sports_saber.webp" alt="sports saber" className="w-100" loading="lazy" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="screen screen9">
                                        <img src="/assets/image/marketing/tea_tox.webp" alt="tea tox" className="w-100" loading="lazy" />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>

                <div className="section_detail image_section bg_dark">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="marketing_title text-white pb-3">Our Process</h2>
                                <p className="marketing_p text-white mt-4 mb-0">We are experienced artisans when it comes to online store design and crafting beautiful sites across a multitude of platforms. Every site we develop stays true to the latest UI/UX trends and always delivers a smooth shopping experience. Whether it is a redesign, migration, or from scratch, we always deliver the same results: an ROI-driven, conversion-focused masterpiece. We combine aesthetically pleasing designs with conversion rate optimization to boost sales and brand loyalty.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_detail image_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <p className="pricing_p mb-3">Pricing</p>
                                <h2 className="marketing_title mb-5">Affordable packages that suits your budget</h2>
                            </div>
                            <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
                                <div className="package1 d-flex flex-column justify-content-between">
                                    <div>
                                        <span className="marketing_p font_bold">Starter</span>
                                        <p className="live_chat mt-2 mb-4">Basic Shopify Web Development.</p>
                                        <h3 className="marketing_service font_bold">$2,000</h3>
                                        <hr className="my-4 marketing_hr" />
                                        <div>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Shopify Setup and Configuration</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Mobile-Friendly Design</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Basic SEO Optimization</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Payment Gateway Integration</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Email Support</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Casual Branding and Visual Identity</p>
                                        </div>
                                    </div>
                                    <a href="#" className="btn submit_btn package_btn package_dark_btn d-flex justify-content-between">Get Started
                                        <svg className="ms-2 package_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#222222" />
                                        </svg>
                                        <svg className="ms-2 arrow_m_width submit_hover" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#ffffff" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 position-relative mb-xl-0 mb-4">
                                <img src="/assets/image/marketing/popular.webp" alt="popular" className="popular_right" />
                                <div className="package1 bg_dark d-flex flex-column justify-content-between">
                                    <div>
                                        <span className="marketing_p font_bold text-white">Standard</span>
                                        <p className="live_chat mt-2 mb-4 text-white">Medium Shopify Web Development.</p>
                                        <h3 className="marketing_service font_bold text-white"> $4,000</h3>
                                        <hr className="my-4 hr_dark" />
                                        <div>
                                            <p className="live_chat mb-4 d-flex align-items-center text-white"><img src="/assets/image/marketing/dark_tick.svg" alt="tick" className="me-2" />Includes Basic Package Services, Plus</p>
                                            <p className="live_chat mb-4 d-flex align-items-center text-white"><img src="/assets/image/marketing/dark_tick.svg" alt="tick" className="me-2" />Custom Design and Branding</p>
                                            <p className="live_chat mb-4 d-flex align-items-center text-white"><img src="/assets/image/marketing/dark_tick.svg" alt="tick" className="me-2" />Mobile-Responsive Design</p>
                                            <p className="live_chat mb-4 d-flex align-items-center text-white"><img src="/assets/image/marketing/dark_tick.svg" alt="tick" className="me-2" />Advanced SEO Optimization</p>
                                            <p className="live_chat mb-4 d-flex align-items-center text-white"><img src="/assets/image/marketing/dark_tick.svg" alt="tick" className="me-2" />Priority Email Support</p>
                                            <p className="live_chat mb-4 d-flex align-items-center text-white"><img src="/assets/image/marketing/dark_tick.svg" alt="tick" className="me-2" />UI/UX Enhancement for Conversion</p>
                                            <p className="live_chat mb-4 d-flex align-items-center text-white"><img src="/assets/image/marketing/dark_tick.svg" alt="tick" className="me-2" />3D Rendering for Key Products</p>
                                        </div>
                                    </div>
                                    <a href="#" className="btn submit_btn package_btn d-flex justify-content-between">Get Started
                                        <svg className="ms-2 package_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#ffffff" />
                                        </svg>
                                        <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#222222" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="package1 d-flex flex-column justify-content-between">
                                    <div>
                                        <span className="marketing_p font_bold">Ordinance Development</span>
                                        <p className="live_chat mt-2 mb-4">Custom Shopify Web Development - Contact for Quote Complete Customization</p>
                                        <h3 className="marketing_service font_bold">Starting from $10,000</h3>
                                        <hr className="my-4 marketing_hr" />
                                        <div>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Unlimited Products and Categories</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Fully Mobile-Optimized</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Advanced Payment Gateway Integration (multiple gateways)</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Custom Application Development</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Extensive SEO Strategy and Ongoing Optimization</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Dedicated Branding and Comprehensive Visual Identity</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Comprehensive 3D Rendering for All Products</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Unlimited Revisions</p>
                                            <p className="live_chat mb-4 d-flex align-items-center"><img src="/assets/image/marketing/light_tick.svg" alt="tick" className="me-2" />Access to All Web Agency Services: Web Design, UI/UX, Advanced 3D Rendering, Full Branding, and Visual Identity Services</p>
                                        </div>
                                    </div>
                                    <a href="#" className="btn submit_btn package_btn package_dark_btn d-flex justify-content-between">Get Started
                                        <svg className="ms-2 package_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#222222" />
                                        </svg>
                                        <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#ffffff" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_detail image_section pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="product_title mb-0 pb-3">Clients</h2>
                            </div>
                            <div className="col-12 mt-4 overflow-hidden position-relative slider_height">
                                <div className="d-flex align-items-center justify-content-center w-100 slider_img">
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/yogabar.svg" alt="yogabar" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/anand.svg" alt="anand sweets" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/humdard.svg" alt="hamdard" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/om.svg" alt="om sweets" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/bayla_skin.svg" alt="bayla skin" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/dr_vaidyas.svg" alt="dr vaidyas" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/gulab.svg" alt="gulab" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/chhapanbhog.svg" alt="chhapanbhog" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/india_sweets.svg" alt="india sweets" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/prakruti.svg" alt="prakruti" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/sport_saber.svg" alt="sport saber" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/nourysh.svg" alt="nourysh" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/maa_baby.svg" alt="maa & baby" />
                                    </div>
                                    <div className="marketing_slider">
                                        <img src="/assets/image/clients/helthoxide.svg" alt="helthoxide" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_detail image_section pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <h2 className="marketing_title pb-md-5 pb-4">What our client says?</h2>
                            </div>
                        </div>
                        <div className="d-lg-block d-none">
                            <div className="row client_review">
                                {clientReview.map((i, index) =>
                                    <div className="col-lg-4 col-md-6" key={index}>
                                        <div className="clients_card">
                                            <div>
                                                <img src="/assets/image/marketing/star_fill.svg" alt="star" />
                                                <img src="/assets/image/marketing/star_fill.svg" alt="star" />
                                                <img src="/assets/image/marketing/star_fill.svg" alt="star" />
                                                <img src="/assets/image/marketing/star_fill.svg" alt="star" />
                                                <img src="/assets/image/marketing/star_blank.svg" alt="star" />
                                            </div>
                                            <h3 className="marketing_p mb-2 mt-3">{i.title}</h3>
                                            <p className="live_chat mb-4">{i.review}</p>
                                            <hr />
                                            <div className="d-flex align-items-center justify-content-between">
                                                <p className="live_chat">{i.clientname}</p>
                                                <p className="live_chat contact_span">{i.clientcompany}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-block d-lg-none">
                            <div className="row">
                                <div className="col-12">
                                    <Swiper
                                        // slidesPerView={1}
                                        speed={2000}
                                        autoHeight={true}
                                        spaceBetween={0}
                                        autoplay={false}
                                        navigation={false}
                                        pagination={false}
                                        draggable={true}
                                        loop={false}
                                        breakpoints={{
                                            360: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            375: {
                                                slidesPerView: 1.1,
                                                spaceBetween: 10,
                                            },
                                            430: {
                                                slidesPerView: 1.3,
                                                spaceBetween: 15,
                                            },
                                            576: {
                                                slidesPerView: 1.6,
                                                spaceBetween: 15,
                                            },
                                            768: {
                                                slidesPerView: 1.8,
                                                spaceBetween: 15,
                                            },
                                        }}
                                        modules={[Navigation, Pagination, Autoplay]}
                                        className="mySwiper"
                                    >
                                        {clientReview.map((i, index) =>
                                            <SwiperSlide>
                                                <div key={index}>
                                                    <div className="clients_card">
                                                        <div>
                                                            <img src="/assets/image/marketing/star_fill.svg" alt="star" />
                                                            <img src="/assets/image/marketing/star_fill.svg" alt="star" />
                                                            <img src="/assets/image/marketing/star_fill.svg" alt="star" />
                                                            <img src="/assets/image/marketing/star_fill.svg" alt="star" />
                                                            <img src="/assets/image/marketing/star_blank.svg" alt="star" />
                                                        </div>
                                                        <h3 className="marketing_p mb-2 mt-3">{i.title}</h3>
                                                        <p className="live_chat mb-4">{i.review}</p>
                                                        <hr />
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <p className="live_chat">{i.clientname}</p>
                                                            <p className="live_chat contact_span">{i.clientcompany}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ContactForm />

                <Footer />

            </GoogleReCaptchaProvider>
        </>
    );
}