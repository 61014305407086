import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";
// import useScrollRedirect from "../hooks/useScrollRedirect";

export default function PortfolioNurdle() {

    // useScrollRedirect('/portfolio/anandsweets')

    return (
        <>
            <Helmet>
                <title>Nurdle | Custom Web Design & Development Portfolio | aBox Agency</title>
                <meta name="title" content="Nurdle | Custom Web Design & Development Portfolio | aBox Agency" />
                <meta name="description" content="Explore our portfolio showcasing custom web design and development for Nurdle, offering vibrant kids' underwear crafted with care, colorful designs, premium quality, and free surprise gifts! Discover how aBox Web Agency creates engaging and user-friendly websites to elevate children's apparel brands." />
                <meta name="keywords" content="Nurdle, custom web design, web development, kids' underwear, children's apparel, vibrant designs, premium quality, surprise gifts, ecommerce website, online store, web agency portfolio, web design agency, digital marketing, SEO optimization, user-friendly design, responsive web design, colorful underwear, children's fashion, clothing brand, creative designs, brand identity, online shopping, children's clothing, playful designs" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/nurdle" />
            </Helmet>

            <Header />

            <div className="page_bg bg_nurdle">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Nurdle</p>
                                </div>
                                <div className="w-100">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">
                                        Nurdle <br />
                                        <span className="nurdle_title">
                                            Infusing Flavor into the Digital Realm
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white mb-3">Arospice, a beloved name in Indian Spices & Millets, was ready to spice up their online game. They came to us at aBox for a digital makeover, and we were thrilled to help! We revamped their website on Shopify, packed it with essential features, and ensured it was e-commerce ready. Plus, we gave their brand identity a fresh twist and established a strong online presence. Today, Arospice’s vibrant website is a testament to our shared passion for quality and flavor.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://nurdle.in/" target="_blank" className="d-flex align-items-center nurdle_title">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="nurdle_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce Solutions</li>
                                                <li>UI/UX Design</li>
                                                <li>Website Development</li>
                                                <li>Brand Identity</li>
                                                <li>Web Design</li>
                                                <li>Search Engine Optimization (SEO)</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="nurdle_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Indian Spices and Millets</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_nurdle_logo">
                                <img src="/assets/image/portfolios/nurdle/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/nurdle/nurdle1.webp" className="w-100" alt="nurdle" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title nurdle_title">Breathing New Life into Arospice’s Digital Presence</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        At aBox, our talented team worked their magic to give Arospice’s online presence a major upgrade. We created a visually stunning and super user-friendly Shopify website. The new site offers a seamless shopping experience, perfectly showcasing their rich variety of spices and millets.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/nurdle/nurdle2.webp"
                                        alt="nurdle" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/nurdle/nurdle3.webp"
                                            alt="nurdle" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/nurdle/nurdle4.webp"
                                            alt="nurdle" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <div className="d-flex justify-content-between flex-column gap_10 gap_20">
                                        <img src="/assets/image/portfolios/nurdle/nurdle5.webp"
                                            alt="nurdle" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/nurdle/nurdle6.webp"
                                            alt="nurdle" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10 gap_20">
                                        <img src="/assets/image/portfolios/nurdle/nurdle7.webp"
                                            alt="nurdle" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/nurdle/nurdle8.webp"
                                            alt="nurdle" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/nurdle/nurdle9.webp"
                                        alt="nurdle" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect nurdle_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/nurdle/nurdle10.webp"
                                            alt="nurdle" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/nurdle/nurdle11.webp"
                                            alt="nurdle" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title nurdle_title">Where Tradition Meets Modern E-Commerce Magic</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Arospice, known for their top-notch Indian spices and millets, now has a digital home that blends traditional flavors with modern e-commerce flair. Visit <a href="https://nurdle.in/" target="_blank" className="official_link omsweets_text">nurdle.in</a> and dive into their world of aromatic spices and wholesome millets. It’s quality and authenticity you can taste with every click.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/nurdle/nurdle12.webp"
                                        alt="nurdle" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/nurdle/nurdle13.webp"
                                        alt="nurdle" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/nurdle/nurdle14.webp"
                                        alt="nurdle" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/nurdle/nurdle15.webp"
                                        alt="nurdle" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/nurdle/nurdle16.webp"
                                        alt="nurdle" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title nurdle_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/nurdle/nurdle17.webp" className="w-100" alt="nurdle color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/nurdle/nurdle18.webp" alt="nurdle font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text-white perform_pera">Nurdle here, and let me tell you, Abox is the bee's knees! They took our jumbled ideas for a baby clothes and care website and whipped them into something magical. Had a wonderful experience curating my e-Commerce store,they helped me build a unique and clutter free site with shopify. A set of highly talented and eager to work individuals. Great work.!</p>
                                    <h3 class="title_color padd_s nurdle_title">Nurdle</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Nurdle</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg aurolab_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/aurolab" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}