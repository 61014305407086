import React, { useEffect, useRef } from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import PortfolioHorizontalSlider from "../component/PortfolioHorizontalSlider";

gsap.registerPlugin(ScrollTrigger);

export default function ServiceECommerce() {

    const section1Ref = useRef(null);
    const colLeftRef = useRef(null);

    useEffect(() => {
        if (window.innerWidth > 1024) {
            function raf(time) {
                ScrollTrigger.update();
                requestAnimationFrame(raf);
            }

            requestAnimationFrame(raf);

            const section1 = section1Ref.current;
            const colLeft = colLeftRef.current;
            const timeline = gsap.timeline({ paused: true });

            timeline.fromTo(
                colLeft,
                { y: 0 },
                { y: '170vh', duration: 1, ease: 'none' },
                0
            );

            ScrollTrigger.create({
                animation: timeline,
                trigger: section1,
                start: 'top top',
                end: 'bottom center',
                scrub: true,
            });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Expert Shopify Development | Boost Your Online Store | Box Web Agency</title>
                <meta name="title" content="Expert Shopify Development | Boost Your Online Store | Box Web Agency" />
                <meta name="description" content="Elevate your online store with expert Shopify development services from Box Web Agency. Let us enhance your e-commerce platform to drive sales and provide an exceptional user experience." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Expert Shopify Development, E-commerce Solutions, Boost Online Store, Shopify Experts, E-commerce Development, Online Retail Solutions, Shopify Store Enhancement, E-commerce Website Design, User-Centric Shopping, Online Sales Growth, Web Agency E-commerce, Shopify Store Customization, E-commerce Website Development, Shopify Store Setup, Professional E-commerce Solutions, User-Friendly E-commerce, e-commerce Website, Optimization, Online Shopping Experience, Shopify Store Design, Web Agency, Services, Custom Shopify Development, E-commerce Strategy, Shopify Store Performance, E-commerce Success, Expert E-commerce Solutions" />
                <link rel="canonical" href="https://www.abox.agency/services/shopify" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="header_padding">
                <div className="section_detail">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-7 sec_padding shopi_padding">
                                <h1 className="service_heading">Custom Shopify Web Design & Development</h1>
                                <p className="seo_p">Unlock your business potential with a Custom Shopify store and E-commerce store! Boost sales, enhance user experience, and stand out from the competition. At aBox agency, we craft unique e-commerce solutions tailored to your brand, creating a seamless shopping journey for your customers. Let's elevate your online success together!</p>
                            </div>
                            <div className="col-lg-5 pe-0 text-end ps-lg-3 ps-0">
                                <div>
                                    <img src="/assets/image/service/shopify.webp" alt="shopify" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* seo detail section */}
            <div className="section_detail seo_section">
                <div className="container">
                    <div className="row padding_bottom border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Futuristic Shopify Web Design and Development for business’s instant results</h2>
                        </div>
                        <div className="col-xl-6">
                            <p className="seo_content">Experience instantaneous business transformation like never before with our future-forward Shopify web design and development services at aBox Agency. Our progressive technique combined with captivating, superior-quality designs ensures that your stores maximize user engagement.</p>
                            <p className="seo_content mb-0">Harness the power of customized solutions offered by our team of specialized designers and developers who are singularly focused on making your Shopify store a runaway success. Our services range from beautifying your Shopify store to handling complex Shopify projects with a strategic business focus and an unwavering commitment to client service.</p>
                        </div>
                    </div>
                    <div className="row feature_padding border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">The best features We provide</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex flex-wrap">
                                <p className="feature_p">Custom Store</p>
                                <p className="feature_p">custom categorization</p>
                                <p className="feature_p">Payment integration</p>
                                <p className="feature_p">Mobile responsiveness</p>
                                <p className="feature_p">Inventory management</p>
                                <p className="feature_p">Customer reviews</p>
                                <p className="feature_p">SEO optimization</p>
                                <p className="feature_p">Analytics tracking</p>
                            </div>
                        </div>
                    </div>
                    <div className="row feature_padding pb-0">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Why aBox?</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Expertise in Shopify</h3>
                                    <p className="seo_content mb-0 font_light">The prowess exhibited by aBox Organization in the domain of Shopify is truly remarkable. Our unparalleled expertise in store development, meticulous design, and flawless functionality set us apart from the competition.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Seamless Integration and Scalability</h3>
                                    <p className="seo_content mb-0 font_light">Seamless integration and scalability are key to achieving harmonious growth and adaptability in the ever-evolving digital landscape. It optimizes efficiency, enhances customer experience, and ensures adaptability in a dynamic digital landscape.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Futuristic Designs</h3>
                                    <p className="seo_content mb-0 font_light">Immerse yourself in futuristic designs that push the boundaries of creativity and functionality. Discover visionary concepts that blend innovation and aesthetics, shaping a world where imagination meets practicality in captivating ways.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Advanced Functionality</h3>
                                    <p className="seo_content mb-0 font_light">Discover the depth of advanced functionality, where innovation knows no bounds. Harnessing state-of-the-art technologies, businesses can unlock new dimensions of productivity, efficiency, and user experience, propelling themselves to the forefront of their industries.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* detail section */}
            <div className="section_detail image_section detail_section" ref={section1Ref}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" ref={colLeftRef}>
                            <h2 className="service_h2 title_margin">Unlock your online potential <br className="d-md-block d-none" />
                                with Shopify's E-Commerce solutions.</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-2">E/1</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">User-Centric Interface</h3>
                                    <p className="services_p">aBox prioritizes your needs with a user-centric interface. Our Shopify design ensures an intuitive and enjoyable experience for your website visitors. By focusing on simplicity and usability, we create interfaces that engage and guide users seamlessly, making their journey on your website effortless and satisfying.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-2">E/2</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Made-to-order</h3>
                                    <p className="services_p">At aBox Agency, we offer Personalized Shopify web design and development services. Our team creates customized websites on the Shopify platform, tailored specifically to your unique needs. From eye-catching designs to seamless functionality, we deliver personalized solutions that set your online presence apart.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-2">E/3</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Stunning designing</h3>
                                    <p className="services_p">We create stunning designs that are visually captivating and impactful, leaving a long-term impression on your audience. We combine creativity and expertise to craft websites that stand out and represent your brand in the most impressive way possible.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-2">E/4</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Complete Shopify Store Facelift</h3>
                                    <p className="services_p">Revamp your online business with a captivating and mobile-responsive Shopify redesign by aBox Agency. Our expert team will craft a visually stunning visual identity and optimize the user experience, ensuring seamless navigation and effortless product discovery. Elevate your store's appeal and drive conversions with our complete Shopify redesign services.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-2">E/5</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Shopify Plus Experts</h3>
                                    <p className="services_p">Achieve remarkable success with our expertise by your side. From Seamless Migrations, Performance Optimization, Integration and Automation, Enterprise-Level security, and strategic guidance to customizations and advanced development, we unlock your store's full potential. Scale your business with confidence.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-2">E/6</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Shopify App Development</h3>
                                    <p className="services_p">Supercharge your Shopify store with custom app development. Over here, we create powerful, Custom Shopify apps to enhance functionality and meet your unique business needs. Whether you need custom features or seamless integration with existing tools like Front-end and Back-end Development, Testing and Quality Assurance, Requirements Analysis, and integration, you’ll get everything. We deliver the perfect app to drive your online success.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-2">E/7</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Shopify Audits</h3>
                                    <p className="services_p">Maximize your Shopify store's potential with aBox Agency's Shopify audits and consulting. We analyze your store's setup, design, and performance to provide actionable recommendations for improved conversion rates, user experience, and SEO. Unlock the full potential of your Shopify store with our strategic guidance and optimization expertise.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin mb-0">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-2">E/8</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Back-End Productivity</h3>
                                    <p className="services_p">Boosting efficiency behind the scenes Simplify and streamline your website's back-end operations with our expert solutions. We optimize coding, database management, and server performance to enhance productivity. Focus on what matters most while we handle the technical complexities for you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PortfolioHorizontalSlider />

            <LetsTalk />

            <Footer />
        </>
    );
}