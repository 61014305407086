import React, { useEffect, useRef } from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import PortfolioHorizontalSlider from "../component/PortfolioHorizontalSlider";

gsap.registerPlugin(ScrollTrigger);

export default function ServiceVisualIdentity() {

    const section1Ref = useRef(null);
    const colLeftRef = useRef(null);

    useEffect(() => {
        if (window.innerWidth > 1024) {
            function raf(time) {
                ScrollTrigger.update();
                requestAnimationFrame(raf);
            }

            requestAnimationFrame(raf);

            const section1 = section1Ref.current;
            const colLeft = colLeftRef.current;
            const timeline = gsap.timeline({ paused: true });

            timeline.fromTo(
                colLeft,
                { y: 0 },
                { y: '170vh', duration: 1, ease: 'none' },
                0
            );

            ScrollTrigger.create({
                animation: timeline,
                trigger: section1,
                start: 'top top',
                end: 'bottom center',
                scrub: true,
            });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Powerful Branding and Visual Identity | Box Web Agency</title>
                <meta name="title" content="Powerful Branding and Visual Identity | Box Web Agency" />
                <meta name="description" content="Transform your brand with powerful branding and visual identity services from Box Web Agency. Our creative expertise helps you establish a compelling brand presence that resonates with your audience." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Powerful Branding, Visual Identity Services, Brand Transformation, Creative Branding Solutions, Compelling Visual Identity, Brand Recognition, Effective Brand Communication, Brand Strategy, Creative Design, Professional Branding, Memorable Visual Identity, Consistent Brand Presence, Branding Excellence, Branding Consultation, Visual Impact, Unique Brand Identity, Web Agency Branding, Branding and Design, Branding Expertise, Branding Success, Audience Engagement, Branding Solutions, Branding Process, Visual Identity Creation, Establish Brand Presence" />
                <link rel="canonical" href="https://www.abox.agency/services/visualidentity" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="header_padding">
                <div className="section_detail">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-7 sec_padding">
                                <h1 className="service_heading">Innovative Branding <br className="d-xxl-block d-none" /> Services</h1>
                                <p className="seo_p">Unlock the power of Visual Identity with aBox Agency!  Elevate your brand's recognition and credibility, attract more customers, and stand out in the digital realm. Let us craft a compelling brand story through stunning logos, designs, and web solutions. Embrace success with aBox Agency!</p>
                            </div>
                            <div className="col-lg-5 pe-0 text-end ps-lg-3 ps-0">
                                <div>
                                    <img src="/assets/image/service/visual_indentity.webp" alt="visual indentity" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* seo detail section */}
            <div className="section_detail seo_section">
                <div className="container">
                    <div className="row padding_bottom border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Branding and design that have instant impacts</h2>
                        </div>
                        <div className="col-xl-6">
                            <p className="seo_content">Unleash instant impact with our branding services. From captivating brand identities to visually stunning designs, we give your business an instant boost. Trust us to create a strong and memorable presence that sets you apart.</p>
                            <p className="seo_content mb-0">At aBox, your brand will stand out and make an immediate impact. Our professionals excel at crafting captivating designs and brand identities that resonate with your target audience. From visually striking logos to compelling marketing materials, we ensure your business leaves a lasting impression.</p>
                        </div>
                    </div>
                    <div className="row feature_padding border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">The best features We provide</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex flex-wrap">
                                <p className="feature_p">Logo and Labeling</p>
                                <p className="feature_p">Color palette</p>
                                <p className="feature_p">Typography selection</p>
                                <p className="feature_p">Iconography</p>
                                <p className="feature_p">Brand guidelines</p>
                                <p className="feature_p">Consistent imagery</p>
                                <p className="feature_p">Visual storytelling</p>
                                <p className="feature_p">Brand elements and graphics</p>
                            </div>
                        </div>
                    </div>
                    <div className="row feature_padding pb-0">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Why aBox?</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Brand Storytelling</h3>
                                    <p className="seo_content mb-0 font_light">Harness the power of brand storytelling to create a lasting emotional connection with your audience, cultivate brand loyalty, differentiate your business, and elevate your overall brand presence in the market.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Visual Branding</h3>
                                    <p className="seo_content mb-0 font_light">Experience the transformative benefits of visual branding as we enhance your brand's identity through captivating designs, fostering recognition, building trust, and creating a lasting impression in the minds of your audience.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Strategic Brand Positioning</h3>
                                    <p className="seo_content mb-0 font_light">Achieve a competitive edge with our strategic brand positioning services. We strategically position your brand in the market, helping you stand out, connect with your target audience, and drive long-term success.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Interactive Brand Experiences</h3>
                                    <p className="seo_content mb-0 font_light">Engage your audience with immersive and interactive brand experiences. Our team creates captivating encounters that leave a lasting impact, forging strong connections between your brand and customers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* image section */}
            <div className="section_detail image_section detail_section visual_section" ref={section1Ref}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" ref={colLeftRef}>
                            <h2 className="service_h2 title_margin">Create a lasting impression <br className="d-md-block d-none" />
                                with captivating visual branding.</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">V/1</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Appealing aspect</h3>
                                    <p className="services_p">Discover the transformative power of our appealing aspect. With a unique blend of technology, innovative design, and strategic thinking, we empower your business to reach new heights and stay ahead in the competitive landscape. Release your full potential with our in-depth solutions and achieve remarkable success.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">V/2</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Brand identity</h3>
                                    <p className="services_p">Create a Unique and recognizable brand identity that merges with your target audience. Our team of experts will collaborate with you to create an exclusive and recognizable brand image that sets you apart from the competition. From logo design to color schemes and brand guidelines, we'll ensure your brand identity reflects the center of your business and leaves a lasting impression on your customers. Elevate your brand with our expertise.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">V/3</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Approaching and Accents</h3>
                                    <p className="services_p">Gathers a global audience with our expertise in approaching diverse markets and accents. We understand the importance of cultural sensitivity and linguistic shades, allowing us to create your content and communicate with different regions and audiences. Whether it's getting your website ready for international markets or creating a localized approach, we make sure that your brand stands out to customers worldwide. Expand your reach with our approach and highlight your global presence. </p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">V/4</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Beyond appearances</h3>
                                    <p className="services_p">At aBox, we believe in looking beneath the surface. In designing websites, we prioritize functionality over aesthetics, putting equal emphasis on how well they function and how well they serve their users. With our expertise, your online presence will stand out for all the right reasons, driving real results for your business.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">V/5</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Brand Collateral</h3>
                                    <p className="services_p">From business cards and stationery to brochures and packaging, we create captivating brand collateral that leaves a lasting impression on your customers. Our attention to detail and commitment to quality shine through in every piece of collateral we produce.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">V/6</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Content Creation</h3>
                                    <p className="services_p">Compelling content is essential for engaging your target audience and driving brand awareness. Our experienced content creators develop high-quality content across various formats, including website copy, blog articles, social media posts, and multimedia content. We ensure that the content aligns with your brand's voice and resonates with your target audience, effectively conveying your brand's message.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">V/7</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Brand Monitoring and Analysis</h3>
                                    <p className="services_p">Tracking and analyzing your brand's performance is vital for continuous improvement. We employ advanced analytics tools to monitor brand mentions, sentiment, and customer feedback. This data-driven approach enables us to make informed decisions and refine your brand strategy for maximum impact.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin mb-0">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">V/8</p>
                                </div>
                                <div className="col-md-11 col-12 pe-4">
                                    <h3 className="services_h3">Visual Identity</h3>
                                    <p className="services_p">A visually appealing and memorable logo is the cornerstone of a strong brand identity. Our talented designers work closely with you to create a captivating logo that represents your brand's essence. We also develop a cohesive visual identity, including color palettes, typography, and design elements, to ensure a consistent brand experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PortfolioHorizontalSlider />

            <LetsTalk />

            <Footer />
        </>
    );
}